import {ReactComponent as ArrowIcon} from "../../assets/icons/arrow_left.svg";
import ItemImg1 from "../../assets/images/store-item-1.png";
import ItemImg2 from "../../assets/images/store-item-2.png";
import ItemImg3 from "../../assets/images/store-item-6.png";
import ItemImg4 from "../../assets/images/store-item-4.png";
import ItemImg5 from "../../assets/images/store-item-5.png";
import React, {useState, useContext} from "react";
import {useNavigate} from "react-router";
import {AuthContext} from "../../context/AuthContext";
import Button from "../../components/common/Button";
import PointImg from "../../assets/images/img_point.png";
import AdVideoImg from "../../assets/images/img_ad.png";
import ModalDialog from "../../components/common/Modal";
import ToastAlert from "../../components/common/Toast";
import useSWR from "swr";
import {getPaidToWatchAds, useAdsListener, useTempAdsListener} from "../../services/admob";
import {useScript, useRunTimeEnvironment} from "../../utils/hooks";

export default function ItemStore() {

  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState("");
  const [selectedPrice, setSelectedPrice] = useState(0);
  const [adKey, setAdKey] = useState();
  const [adPath, setAdPath] = useState();
  const [adItemNo, setAdItemNo] = useState();
  const [alertMessage, setAlertMessage] = useState(false);
  const { API_SERVER_DOMAIN, useAccessToken, fetchWithToken } = useContext(AuthContext);
  const accessToken = useAccessToken();
  const RTE = useRunTimeEnvironment();
  const { data: gameItems, mutate } = useSWR(accessToken && [`${API_SERVER_DOMAIN}/game/items`, accessToken], fetchWithToken);

  const itemDetail = {
    1: {
      title: "[부스터]",
      description: "속도가 2배 빨라짐(8초) / 쿨타임 5초",
      imgUrl: ItemImg1
    },
    2: {
      title: "[보호막]",
      description: "뺏기, 공격 아이템 방어(7초) / 쿨 타임 12초",
      imgUrl: ItemImg2
    },
    3: {
      title: "[연막]",
      description: "자기 위치에서 발동 되며, 연막안에 있는 유저는 1.5초 동안 정지 상태가 됨 / 쿨타임 60초",
      imgUrl: ItemImg3
    },
    4: {
      title: "[은신술]",
      description: "토리를 보유하고 도망 다니는 집사만 사용 가능한 아이템, 다른 유저들이 볼 수 없음 (6초) / 쿨타임 30초",
      imgUrl: ItemImg4
    },
    5: {
      title: "[투시경]",
      description: "은신술 아이템을 사용한 집사를 볼 수 있음(6초) / 쿨타임 20초",
      imgUrl: ItemImg5,
      // disabled: true
    }
  };

  const purchaseGameItem = async (itemNo, itemName, itemPoint) => {
    const response = await fetch(`${API_SERVER_DOMAIN}/gate/point/game/item?itemNo=${itemNo}`, {
      headers: {
        "Authorization": `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      }
    });
    const jsonData = await response.json();
    if (jsonData.result === "ok") {
      setSelectedTitle(itemName);
      setSelectedPrice(itemPoint);
      setModalOpen(true);
    } else {
      alert(jsonData.message);
    }
  }

  const completePurchaseGameItem = () => {
    setModalOpen(false);
    mutate();
  }

  useScript(`${process.env.PUBLIC_URL}/flutter/flutter_script.js`);

  const startAds = async (path, itemNo) => {
    const response = await getPaidToWatchAds(RTE, `${API_SERVER_DOMAIN}${path}`, accessToken);
    if (response.adKey) {
      setAdKey(response.adKey);
      setAdPath(path);
      setAdItemNo(itemNo);
    } else {
      setAlertMessage(response.message);
    }
  }
  // 광고 시청 보상 지급
  // 테스트
  // useTempAdsListener(`${API_SERVER_DOMAIN}${adPath}`, accessToken, {adKey: adKey, itemNo: adItemNo}, mutate);
  useAdsListener(`${API_SERVER_DOMAIN}${adPath}`, accessToken, {adKey: adKey, itemNo: adItemNo}, mutate);

  if (!gameItems) return;

  return (
    <section>
      <div className="page-header header-shadow">
        <ArrowIcon onClick={() => navigate("/tory-game")} style={{left: 24}}/>
        <h3>아이템 상점</h3>
      </div>

      <div className="store-item-list">
        {gameItems.infos.map((item) => (
          <div key={item.itemNo} className={itemDetail[item.itemNo].disabled ? "disabled-item" : ''}>
            <div className="img-wrapper">
              <img src={itemDetail[item.itemNo].imgUrl} alt={item.itemName} height={70}/>
            </div>
            <div>
              <h4>[{item.itemName}] <small>내 보유개수 {item.itemCount}개</small></h4>
              <p className="text-grey">{itemDetail[item.itemNo].description}</p>
              <div>
                <Button disabled={itemDetail[item.itemNo].disabled} onClick={() => startAds(`/gate/ad/game/item?itemNo=${item.itemNo}`, item.itemNo)}>
                  <img src={AdVideoImg} alt="video_icon" height={20}/>광고({item.adCount}/{item.maxAdCount})
                </Button>
                <Button disabled={itemDetail[item.itemNo].disabled}
                        onClick={() => {
                          purchaseGameItem(item.itemNo, item.itemName, item.point);
                        }}>
                  <img src={PointImg} alt="point_image" width={24}/>
                  {item.point}
                </Button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <ModalDialog open={modalOpen}
                   title={"구매 완료"} className="alert-modal"
                   /* onClose={completePurchaseGameItem} */>
        <p className="alert-msg" style={{minWidth: 250}}>{selectedTitle}아이템 1개를 구매 하셨어요.</p>
        <div className="item-purchase-points">
          <span>-{selectedPrice}</span>
          <img src={PointImg} alt="point_image" width={24}/>
        </div>
        <Button onClick={completePurchaseGameItem}>확인</Button>
      </ModalDialog>

      <ToastAlert show={!!alertMessage} onClose={setAlertMessage} text={alertMessage} />
    </section>
  )
}