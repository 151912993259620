import "../assets/styles/login.scss";
import {useContext, useEffect, useState} from "react";
import {AuthContext} from "../context/AuthContext";
import {useNavigate} from "react-router";
import LoginImg from "../assets/images/yaong_login.png";
import KakaoIcon from "../assets/icons/kakao-login.png";
import NaverIcon from "../assets/icons/naver-login.png";
import GoogleIcon from "../assets/icons/google-login.png";
import AppleIcon from "../assets/icons/apple-login.png";
import Loading from "../assets/icons/Loading.png";
import {useRunTimeEnvironment} from "../utils/hooks";
import ToastAlert from "../components/common/Toast";

export default function LoginPage() {

  const {API_SERVER_DOMAIN, signInTest, useAccessToken} = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState(false);
  const navigate = useNavigate();
  const token = useAccessToken();
  const RTE = useRunTimeEnvironment();
  const urlParams = new URLSearchParams(window.location.search);
  const error = urlParams.get("err");

  const openOAuthWindow = snsName => {
    let clientId = "";
    let url = "";
    let redirectUri = encodeURIComponent(`${API_SERVER_DOMAIN}/auth/sso/${snsName}`);

    switch (snsName) {
      case "kakao":
        clientId = encodeURIComponent("ce14592facabd00d83ba54056aa7117f");
        url =`https://kauth.kakao.com/oauth/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}`;
        break;
      case "naver":
        clientId = encodeURIComponent("7quR1FTeUB4vxeVfD3FV");
        url = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&state==1123`;
        break;
      case "google":
        clientId = encodeURIComponent("592987376634-pcau3np74emhgs9n04vkcbc48u1ce318.apps.googleusercontent.com");
        url = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=email%20profile%20openid&access_type=offline`;
        break;
      case "apple":
        clientId = encodeURIComponent("app.yaong.com");
        url = `https://appleid.apple.com/auth/authorize?response_type=code%20id_token&response_mode=form_post&scope=name%20email&client_id=${clientId}&redirect_uri=${redirectUri}`;
        break;
    }

    window.location.href = url;
    // window.open(url, 'oauthWindown', 'titlebar=1, resizable=1, scrollbars=yes, width=600, height=650');
  }

  useEffect(() => {
    // 로그인 된 상태면 초기화면으로 이동
    if (!error && token) {
      // const home = RTE === "APP" ? "/playground" : "/pool";
      navigate("/playground", {replace: true});
    }

    if (error === "REREG-RULE-VIOLAT") {
      setAlertMessage("이미 탈퇴 한 아이디예요. 회원가입은 탈퇴일로부터 7일 후 가능해요.");
    }

    // 로딩 화면
    setTimeout(() => {
      setLoading(false)
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function login() {
    signInTest("test", "29");
  }

  return (
    <section className="login-page">
      <h1>야옹상회</h1>
      <p>야무지고 옹골찬 포인트 혜택을 만나보세요.</p>
      <div className="login-actions">
      {loading ?
        <LoadingIcon />
      :
        <>
          <img src={LoginImg} alt="login" style={{margin: "20px 0"}}/>
          {/* <button onClick={login}> */}
          <button onClick={() => {openOAuthWindow('kakao')}}>
            <img src={KakaoIcon} alt="kakao-icon" width={20}/>
            <span>카카오로 로그인</span>
          </button>
          {RTE === "APP" && 
          <>
            {/* <button onClick={() => {openOAuthWindow('naver')}}>
              <img src={NaverIcon} alt="naver-icon" width={20}/>
              <span>네이버로 로그인</span>
            </button> */}
            <button onClick={() => {openOAuthWindow('google')}}>
              <img src={GoogleIcon} alt="google-icon" width={20}/>
              <span>구글로 로그인</span>
            </button>
            <button onClick={() => {openOAuthWindow('apple')}}>
              <img src={AppleIcon} alt="apple-icon" width={20}/>
              <span>Apple로 로그인</span>
            </button>
          </>
          }
          <footer>가입 시 야옹상회의 서비스<a href="https://cream-cushion-517.notion.site/af425ee8e2e144a986305efa80772377?pvs=4" target="_blank">이용약관</a>과 <a href="https://plip.kr/pcc/e72d3829-a44c-486a-963d-f027390015b1/consent/3.html" target="_blank">개인정보 수집 및 이용</a> 및 <a href="https://plip.kr/pcc/e72d3829-a44c-486a-963d-f027390015b1/consent/4.html" target="_blank">제 3자 정보제공</a>에 동의한 것으로 간주됩니다.</footer>
        </>
      }
      </div>
      <ToastAlert show={!!alertMessage} onClose={setAlertMessage} text={alertMessage} />
    </section>
  )
}

function LoadingIcon() {
  return (
    <div className="loading-circle">
      <img src={Loading} alt="loading" height={24}/>
    </div>
  )
}