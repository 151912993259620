import { useContext, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";

// 카카오 서버에서 호출 https://app.yaongstore.com/kakaolink/callback
export default function KakaoCallback() {
  const urlParams = new URLSearchParams(window.location.search);
  const CHAT_TYPE = urlParams.get("CHAT_TYPE");
  const template_no = urlParams.get("template_no");
  const accessToken = urlParams.get("access_token");
  const { API_SERVER_DOMAIN } = useContext(AuthContext);

  useEffect(() => {
    // 럭키스핀 소환 메시지 보상.
    // 나와의 채팅방에 전송했을 때는 보상 지급하지 않음.
    if (template_no.toString() === "2" && CHAT_TYPE !== "MemoChat") {
      // 친구 소환 횟수 1증가, 보상 지급
      (async () => {
        await fetch(`${API_SERVER_DOMAIN}/pool/luckyspin/invite`, {
          method: 'POST',
          headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          }
        });  
      })();
    }
  }, []);

  return "";
}