const KAKAO_APP_KEY = "9080647ab994e0c212ca0ec12a431e16";
const MOBILE_WEB_URL = "https://app.yaongstore.com/download";
const WEB_URL = "https://app.yaongstore.com/download";

export default function summonFriends(templateNo, refCode = "", token = "") {
  const [title, message, buttonText] = (() => {
    switch (templateNo) {
      case 1:
        return [
          "친구야! 야옹상회 가입하고 같이 포인트 받자!!", 
          "MY 페이지에서 소환코드만 입력해도 1,000포인트 준다냥~",
          "1,000 포인트 바로받기"
        ];
      case 2:
        return [
          "럭키스핀 돌리고 선물받자냥~~", 
          "친구초대하면 무료 돌리기를 준다냥! 신나게 돌리면 푸짐한 선물이 무료로 한 보따리다냥!!", 
          "친구초대하고 스핀 돌기기 무료 1회!"
        ];
    }
  })();

  if (typeof window.flutter_inappwebview !== "undefined") {
    window.flutter_inappwebview?.callHandler("kakao_share", title, message, templateNo);
  } else {
    if (window.Kakao) {
      const Kakao = window.Kakao;
      if (!Kakao.isInitialized()) {
        Kakao.init(KAKAO_APP_KEY);
      }
  
      Kakao.Share.sendDefault({
        objectType: "feed",
        content: {
          title: title,
          description: message,
          imageUrl: "https://app.yaongstore.com/share.png",
          link: {
            mobileWebUrl: `${MOBILE_WEB_URL}?ref=${refCode}`,
            webUrl: `${WEB_URL}?ref=${refCode}`
          }
        },
        buttons: [{
          title: buttonText,
          link: {
            mobileWebUrl: `${MOBILE_WEB_URL}?ref=${refCode}`,
            webUrl: `${WEB_URL}?ref=${refCode}`
          }
        }],
        serverCallbackArgs: {
          template_no: templateNo,
          access_token: token
        }
      });
    }
  }
}