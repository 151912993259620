import React from "react";
import {useEffect} from "react";

export default function ToastAlert({show, onClose, text, timeout = 2500}) {

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        onClose(false);
      }, timeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  return (
    <div className={"toast-alert"} style={{display: show ? "flex" : "none"}}>
      <p>{text}</p>
    </div>
  )
}