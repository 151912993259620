import {ReactComponent as EraseIcon} from "../../assets/icons/erase_icon.svg";
import {useState} from "react";


export function TextField(props) {

  const {
    id, label, placeholder = "",
    helperText, errorText,
    defaultValue = "",
    style, onChange = () => {},
    error = false,
    maxLength
  } = props;
  const [value, setValue] = useState(defaultValue);

  function onChangeValue(e) {
    setValue(e.target.value);
    onChange(e.target.value);
  }

  function clear() {
    setValue("");
    onChange("");
  }

  return (
    <div className={`text-field-wrap${error ? " input-error" : ""}`} style={style}>
      <div className="text-field">
        {label && <label htmlFor={id}>{label}</label>}
        <input id={id} value={value} onChange={onChangeValue} name={id} type="text" placeholder={placeholder} maxLength={maxLength}/>
        {value.length > 0 && <EraseIcon onClick={clear}/>}
      </div>
      {helperText && <p className="text-field-helper">{helperText}</p>}
      {errorText && error && <p className="text-field-error-text">{errorText}</p>}
    </div>
  )
}