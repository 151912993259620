import {ReactComponent as ArrowIcon} from "../../assets/icons/arrow_left.svg";
import React, {useState, useContext, useEffect} from "react";
import {useNavigate} from "react-router";
import AdViewImg from "../../assets/images/adv-view.png";
import PointImg from "../../assets/images/point-large.png";
import PointSmall from "../../assets/images/img_point.png";
import Button from "../../components/common/Button";
import AdVideoImg from "../../assets/images/img_ad.png";
import {BottomPopup} from "../../components/common/Popup";
import {AuthContext} from "../../context/AuthContext";
import {useScript, useRunTimeEnvironment} from "../../utils/hooks";
import {getPaidToWatchAds, useAdsListener} from "../../services/admob";
import ToastAlert from "../../components/common/Toast";
import useSWR from "swr";
import useSWRInfinite from 'swr/infinite'
import {InView} from 'react-intersection-observer';

export default function MyPointsPage() {

  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(1);
  const [pointInfoOpen, setPointInfoOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);
  const [adKey, setAdKey] = useState();
  const [adPath, setAdPath] = useState();
  const { API_SERVER_DOMAIN, useAccessToken, fetchWithToken } = useContext(AuthContext);
  const RTE = useRunTimeEnvironment();
  const accessToken = useAccessToken();

  const { data: profile, mutate } = useSWR(accessToken && [`${API_SERVER_DOMAIN}/user/profile/info`, accessToken], fetchWithToken);
  const getKey = (pageIndex, previousPageData) => {
    if (!accessToken) return null;

    if (previousPageData && !previousPageData.infos.length) return null;
    return [`${API_SERVER_DOMAIN}/user/profile/point/history?page=${pageIndex + 1}&countPerPage=100&type=${selectedTab === 1 ? 'earn' : 'spend'}`, accessToken];
  }
  const { data: history, size, setSize } = useSWRInfinite(getKey, fetchWithToken);

  useScript(`${process.env.PUBLIC_URL}/flutter/flutter_script.js`);
  
  const startAds = async path => {
    const response = await getPaidToWatchAds(RTE, `${API_SERVER_DOMAIN}${path}`, accessToken);
    if (response.adKey) {
      setAdKey(response.adKey);
      setAdPath(path);
    } else {
      setAlertMessage(response.message);
    }
  }
  
  // 광고 시청 보상 지급
  useAdsListener(`${API_SERVER_DOMAIN}${adPath}`, accessToken, {adKey: adKey}, mutate);

  if (!profile || !history) return "";

  return (
    <section className="my-points-page">
      <div className="page-header header-shadow">
        <ArrowIcon onClick={() => navigate("/user")} style={{left: 24}}/>
        <h3>{profile.info.nickname}님의 포인트</h3>
        <button className="info-btn" onClick={() => setPointInfoOpen(true)}>안내</button>
      </div>

      <article>
        <div className="ad-view-box" style={{width: "100%", margin: 0}}>
          <img src={AdViewImg} alt={"points"} width={40} height={40}/>
          <div>
            <p>광고 시청하고</p>
            <p>무료 포인트를 얻으세요</p>
          </div>
          <Button onClick={() => startAds("/gate/ad/point")}>
            <img src={AdVideoImg} alt="video-icon" height={20}/>
            <span>받기</span>
          </Button>
        </div>

        <img src={PointImg} alt="point-icon-large" width={100} style={{margin: '32px 0 20px'}}/>
        <span className="text-grey" style={{fontSize: 15}}>보유중</span>
        <span style={{fontSize: 32, fontWeight: 'bold'}}>{`${profile.info.point.toLocaleString()}`} P</span>
      </article>

      <article className="point-history">
        <div className="basic-tabs">
          <div 
            onClick={() => {
              setSelectedTab(1);
            }}
            className={selectedTab === 1 ? "selected-basic-tab" : ""}
          >
            <span>획득포인트</span>
          </div>
          <div 
            onClick={() => {
              setSelectedTab(2);
            }}
            className={selectedTab === 2 ? "selected-basic-tab" : ""}
          >
            <span>소진 포인트</span>
          </div>
        </div>

        <div className="coupon-list">
          <div>
            <div style={{background: "#F6D7DF"}}>
              <img src={PointSmall} alt={"points"} width={18}/>
            </div>
            <p>포인트 유효기간은 1년이에요.</p>
          </div>
          <ul>
            {history.map((list) => {
              return list.infos.map((item) => (
                <ListItem
                  key={item.createdAt}
                  createdAt={item.createdAt}
                  description={item.description}
                  tabNo={selectedTab}
                  amount={item.amount}
                  type={item.type}
                />
              ))
            })}
          </ul>
          <InView
            as="div"
            className="intersection-observer"
            onChange={(inView, _) => {
              if (inView) {
                setSize(size + 1);
              }
            }}
          ></InView>
        </div>
      </article>

      <BottomPopup title="포인트 안내"
                   open={pointInfoOpen}
                   setOpen={setPointInfoOpen}>
        <ul className="match-info text-grey">
          <li>적립 예정 포인트는 참여 당일 순차적으로 적립됩니다.</li>
          <li>적립된 포인트는 마이페이지&gt;포인트 내역에서 확인 가능합니다.</li>
          <li>포인트 쌓기는 계정당 1대의 기기에서만 참여 가능합니다.</li>
          <li>포인트 쌓기 문의사항은 이용문의를 통해 문의해주세요.</li>
        </ul>
      </BottomPopup>

      <ToastAlert show={!!alertMessage} onClose={setAlertMessage} text={alertMessage} />
    </section>
  )
}

function ListItem(item) {
  return (
    <li className="point-item">
      <span className="text-light">{item.createdAt}</span>
      <div>
        <h4>{item.description}</h4>
        <span>{item.tabNo === 1 && "+"}{item.amount.toLocaleString()}</span>
        {item.type === 'point' && 
          <img src={PointSmall} alt={"points"} width={24}/>
        }
        {item.type === 'item' && '개'}
        {item.type === 'enegry' && '%'}
      </div>
      <span className="text-grey">{item.description}</span>
    </li>
  );
}