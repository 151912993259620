import {ReactComponent as ArrowIcon} from "../../assets/icons/arrow_left.svg";
import React, {useState, useContext, useEffect} from "react";
import {useNavigate} from "react-router";
import FriendsImg from "../../assets/images/friends.png";
import PointsImg from "../../assets/images/1000points.png";
import Button from "../../components/common/Button";
import {ReactComponent as LinkIcon} from "../../assets/icons/vuesax/outline/link-2.svg";
import {ReactComponent as CopyIcon} from "../../assets/icons/vuesax/outline/copy.svg";
import summonFriends from "../../services/summoner";
import {AuthContext} from "../../context/AuthContext";
import useSWR from "swr";
import ToastAlert from "../../components/common/Toast";

export default function InviteFriendsPage() {

  const navigate = useNavigate();
  const {API_SERVER_DOMAIN, useAccessToken, fetchWithToken} = useContext(AuthContext);
  const accessToken = useAccessToken();
  const { data: profile } = useSWR(accessToken && [`${API_SERVER_DOMAIN}/auth/user/check`, accessToken], fetchWithToken);
  const [alertMessage, setAlertMessage] = useState(false);

  useEffect(() => {
    let pageContainer = document.getElementsByClassName("page-container")[0];
    pageContainer?.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!profile || profile.result !== "ok") return;

  return (
    <section>
      <div className="page-header header-shadow">
        <ArrowIcon onClick={() => navigate("/playground")} style={{left: 24}}/>
        <h3>야옹이의 친구소환</h3>
      </div>

      <article className="invite-page">
        <img src={FriendsImg} alt="친구소환" height={170}/>
        <div>
          <div>
            <img src={PointsImg} alt="포인트" width={64} id="reward-icon"/>
            <h1>친구를 소환해줘!</h1>
            <div className="ref-code">
              <p>외로운 야옹이의 친구를 소환해줘 야옹~<br/>친구를 소환하고 나도 친구도 <span>1,000P</span>!</p>
              <div>
                <span>나의 야옹 소환코드</span>
                <span>{profile?.userInfo?.refCode}</span>
              </div>
              <div>
                <Button 
                  disabled={profile.userInfo.inviteBuddyCount === 5}
                  onClick={
                    () => summonFriends(1, profile?.userInfo?.refCode)
                  }
                >
                  <LinkIcon height={20} style={{marginRight: 2}}/>
                  <span>카카오톡</span>
                </Button>
                <Button onClick={async () => {
                  try {
                    await navigator.clipboard.writeText(profile?.userInfo?.refCode);
                  } catch (error) {
                    console.error('clipboard write error: ', error.message);
                  }
                  setAlertMessage('클립보드에 복사되었어요.');
                }}>
                  <CopyIcon height={20} style={{marginRight: 2}}/>
                  <span>코드복사</span>
                </Button>
              </div>
            </div>
          </div>

          <div className="divider"/>

          <div>
            <div>
              <p className="text-grey">
                친구가 가입 후 24시간 내에 소환코드를 입력하면
                나와 친구가 모두 1,000P를 받아요.<br/>
                친구는 최대 5명까지 소환할 수 있어요~</p>
            </div>
            <div className="friends-current">
              <span>친구 소환 현황</span>
              <div className="info-bubble">
                <span><b>{profile.userInfo.inviteBuddyCount}</b> 명</span>
              </div>
            </div>
          </div>
        </div>
      </article>

      <footer className="invite-terms">
        <h3>[유의사항]</h3>
        <ul>
          <li>소환코드 입력은 가입 후 24시간내 까지만 가능해요.</li>
          <li>소환된 친구가 야옹상회 회원가입 이력이 없는 경우에만 적립 대상이 돼요.</li>
          <li>금품 등을 대가로 한 소환코드 거래 시 포인트 회수 및 이용에 제한이 있을 수 있어요.</li>
          <li>지급받은 포인트는 제약 없이 쿠폰 구매 등에 즉시 사용할 수 있어요.</li>
          <li>소환 코드는 1계정 1회만 입력할 수 있어요(탈퇴 후 재가입 포함).</li>
          <li>소환코드는 입력 후 변경할 수 없어요(탈퇴 후 재가입 포함).</li>
          <li>부적절한 방법으로 포인트를 적립한 회원은 포인트 적립이 취소돼요.</li>
          <li>해당 이벤트는 당사 사정에 의해 사전 공지 없이 내용이 변경되거나 기간이 조정될 수 있어요.</li>
        </ul>
      </footer>

      <ToastAlert show={!!alertMessage} onClose={setAlertMessage} text={alertMessage} />
    </section>
  )
}