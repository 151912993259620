import {ReactComponent as CloseIcon} from "../../assets/icons/close.svg";
import React, {useState, useContext} from "react";
import {useNavigate, useLocation} from "react-router";
import {TextField} from "../../components/common/Input";
import Button from "../../components/common/Button";
import ModalDialog from "../../components/common/Modal";
import {SelectField} from "../../components/common/Select";
import {useEffect} from "react";
import ToastAlert from "../../components/common/Toast";
import { AuthContext } from "../../context/AuthContext";

const banks = ["국민은행", "신한은행", "기업은행", "하나은행", "농협은행", "카카오뱅크"];

export default function TicketCancel() {

  const navigate = useNavigate();
  const { state } = useLocation();
  const { API_SERVER_DOMAIN, useAccessToken } = useContext(AuthContext);
  const [textCount, setTextCount] = useState("0");
  const [confirmOpen, setConfirmOpen] = useState(false);

  const [errors, setErrors] = useState([]);
  const [reason, setReason] = useState("");
  const [name, setName] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [userBank, setUserBank] = useState(null);
  const [bankAccount, setBankAccount] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const token = useAccessToken();


  function validateForm() {
    if (reason.length < 10) setErrors(prev => [...prev, "reason"]);
    if (name.length === 0) setErrors(prev => [...prev, "name"]);
    if (phoneNum.length === 0) setErrors(prev => [...prev, "phoneNum"]);
    if (bankAccount.length === 0) setErrors(prev => [...prev, "bankAccount"]);
    if (!userBank) setErrors(prev => [...prev, "userBank"]);

    if (reason.length < 10) {   
      setErrorMessage("취소 사유를 10자 이상으로 입력해 주새요.");
      return;
    }

    if (!name.length) {
      setErrorMessage("예약자 성함을 입력해 주세요.");
      return;
    }

    if (!phoneNum.length) {
      setErrorMessage("연락처를 입력해 주세요.");
      return;
    }

    if (!userBank || !bankAccount.length) {
      setErrorMessage("은행 및 계좌 번호를 입력해 주세요.");
      return;
    }

    setConfirmOpen(true);
  }

  useEffect(() => {
    document.getElementsByClassName("page-container")[0]?.scrollTo(0, 0);
  }, [])

  return (
    <section style={{background: '#F2F2F2'}}>
      <div className="page-header header-shadow">
        <h1>예약 취소하기</h1>
        <CloseIcon onClick={() => navigate("/pool")}/>
      </div>
      <article className="ticket-cancel-page">
        <h2>취소 사유를 입력하세요</h2>
        <div className={`text-area-input${errors.includes("reason") ? " text-area-error" : ""}`} style={{}}>
          <textarea name="cancel-reason" id="reason-input" rows="6"
                    placeholder={"취소 사유를 입력하세요. (최소 10자 이상)"}
                    value={reason}
                    onChange={(e) => {
                      setReason(e.target.value);
                      if (e.target.value.length > 50) {
                        setErrorMessage("50자 초과되어 더 이상 입력이 안되요.");
                        setReason(prev => prev.slice(0, 50));
                        setTextCount(50);
                        return;
                      }
                      setTextCount(e.target.value.length);
                      setErrors(prev => prev.filter(el=>el!=="reason"));
                    }}
                    minLength={10}/>
          <span className="text-count">{textCount}/50</span>
        </div>

        <h2>취소 정보</h2>
        <div className="ticket-cancel-form">
          <TextField id={"reserver-name"} label={"예약자"}
                     value={name} error={errors.includes("name")}
                     onChange={(value) => {
                       setName(value);
                       setErrors(prev => prev.filter(el=>el!=="name"));
                     }}
                     placeholder={"예약자명을 입력하세요."}/>
          <TextField id={"reserver-contact"} label={"연락처"}
                     error={errors.includes("phoneNum")}
                     onChange={(value) => {
                       setPhoneNum(value);
                       setErrors(prev => prev.filter(el=>el!=="phoneNum"));
                     }}
                     helperText={"반드시 예약자 본인의 연락처를 입력해야 합니다."}
                     placeholder={"000-0000-0000"}/>
          <SelectField title={"은행명"} items={banks}
                       value={userBank} error={errors.includes("userBank")}
                       onSelect={(item) => {
                         setUserBank(item);
                         setErrors(prev => prev.filter(el=>el!=="userBank"));
                       }}/>
          <TextField id={"bank-account-number"} label={"계좌번호"}
                     value={bankAccount} error={errors.includes("bankAccount")}
                     onChange={(value) => {
                       setBankAccount(value);
                       setErrors(prev => prev.filter(el=>el!=="bankAccount"));
                     }}
                     placeholder={"계좌번호를 입력하세요."}/>
        </div>
      </article>

      <div className="page-action-btn ticket-cancel-btn">
        <Button className="prev-btn" onClick={() => navigate("/pool/ticket")}>취소하기</Button>
        <Button onClick={validateForm}>예약취소</Button>
      </div>

      <ModalDialog open={confirmOpen}
                   title={"예약 취소하기"} className="alert-modal"
                   onCancel={() => setConfirmOpen(false)}>
        <p className="alert-msg">취소를 하시면 평상 예약 내역이 삭제됩니다. 평상 예약을 취소하시겠어요?</p>
        <div className="alert-action-btn">
          <Button className="prev-btn" onClick={() => setConfirmOpen(false)}>돌아가기</Button>
          <Button  onClick={() => {
            setConfirmOpen(false);
            fetch(`${API_SERVER_DOMAIN}/pool/book`, {
              method: "DELETE",
              headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                book_no: state.bookNo,
                reason: reason,
                name: name,
                phone: phoneNum,
                bank: userBank,
                account: bankAccount
              }),
            })
            .then(response => response.json())
            .then(jsonData => {
              if (jsonData.result === "ok") {
                alert("예약 취소 신청이 등록되었습니다.");
                navigate("/pool/ticket");
              } else {
                console.error(jsonData);
              }
            });
          }}>예약취소</Button>
        </div>
      </ModalDialog>

      <ToastAlert show={!!errorMessage} onClose={setErrorMessage} text={errorMessage} />
    </section>
  )
}