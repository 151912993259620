import {useState} from "react";
import {ReactComponent as InfoCircle} from "../../assets/icons/vuesax/bold/info-circle.svg";
import {ReactComponent as ArrowIcon} from "../../assets/icons/arrow_left.svg";

export function AccordionBox(props) {

  const {defaultOpen = false} = props;
  const [open, setOpen] = useState(defaultOpen);

  return (
    <div className={`accordion-container ${open ? "accordion-open" : "accordion-close"}`}>
      <div className="accordion-head">
        <h3><InfoCircle/>{props.header ?? ""}</h3>
        <div onClick={() => setOpen(!open)}>
          <ArrowIcon style={{transform: open ? "rotate(90deg)" : "rotate(-90deg)"}} />
        </div>
      </div>
      <div className="accordion-body">
        {props.children}
      </div>
    </div>
  )
}