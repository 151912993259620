import { useState, useEffect } from 'react';

export function useDeviceType() {

  const [device, setDevice] = useState('');

  function handleDeviceDetection() {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobile = /iphone|ipad|ipod|android|windows phone/g.test(userAgent);
    const isTablet =
      /(ipad|tablet|playbook|silk)|(android(?!.*mobile))/g.test(userAgent);

    if (isMobile) setDevice('Mobile');
    else if (isTablet) setDevice('Tablet');
    else setDevice('PC');
  }

  useEffect(() => {
    handleDeviceDetection();
    window.addEventListener('resize', handleDeviceDetection);
    return () => window.removeEventListener('resize', handleDeviceDetection);
  }, []);

  return device;
}

export const usePlatform = () => {

  const detectPlatform = () => {
    const normalizedUserAgent = window.navigator.userAgent.toLowerCase();
  
    if (/ip(ad|hone|od)/.test(normalizedUserAgent)) return "ios";
    else if (/android/.test(normalizedUserAgent)) return "android";
    else return "others";
  }

  return detectPlatform();
}

export const useScript = url => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    }
  }, [url]);
};

export const useRunTimeEnvironment = () => typeof window.flutter_inappwebview === "undefined" ? "WEB" : "APP";