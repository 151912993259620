import {ReactComponent as ArrowIcon} from "../../assets/icons/arrow_left.svg";
import {useNavigate} from "react-router";
import {TextField} from "../../components/common/Input";
import Button from "../../components/common/Button";
import {useState} from "react";
import {AlertDialog} from "../../components/common/Modal";
import {useContext} from "react";
import {AuthContext} from "../../context/AuthContext";
import {useLocation} from "react-router-dom";

export default function DeleteAccountPage() {

  const navigate = useNavigate();
  const {API_SERVER_DOMAIN, useAccessToken, signOut} = useContext(AuthContext);
  const {state} = useLocation();
  const accessToken = useAccessToken();

  const [name, setName] = useState("");
  const [error, setError] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);

  async function validate() {
    if (name === state.nickname) {
      const response = await fetch(`${API_SERVER_DOMAIN}/auth/withdrawal`, {
        method: 'POST',
        headers: {
          "Authorization": `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({nickname: state.nickname})
      });
      const jsonData = await response.json();
      if (jsonData.result === "ok") {
        
      } else {
        console.error(jsonData);
      }

      setConfirmOpen(true);
    } else {
      setError(true);
      setErrorAlertOpen(true);
    }
  }

  return (
    <section className="delete-account-page">
      <div className="page-header header-shadow">
        <ArrowIcon onClick={() => navigate(-1)} style={{left: 24}}/>
        <h3>알림/로그인 설정</h3>
      </div>

      <article className="delete-account-form">
        <h3>회원탈퇴</h3>
        <p className="text-light">회원탈퇴 시, 보유한 포인트 및 캐릭터를 포함하여 모든 정보가 삭제되며 14일간 재가입이 불가능합니다.</p>
        <TextField id={"닉네임"} label={"닉네임"}
                   value={name} error={error}
                   onChange={(value) => {
                     setName(value);
                     setError(false);
                   }}
                   placeholder={"본인의 닉네임을 입력하세요."}/>

        <Button onClick={validate}>탈퇴하기</Button>
      </article>
      <AlertDialog open={confirmOpen} title={"요청 완료"}
                   onClose={() => setConfirmOpen(false)}
                   onCancel={() => setConfirmOpen(false)}
                   buttonText={"확인"}
                   onOk={signOut}
                   text={"회원탈퇴가 완료되었습니다."}/>
      <AlertDialog open={errorAlertOpen} title={"알림"}
                   onClose={() => setErrorAlertOpen(false)}
                   onCancel={() => setErrorAlertOpen(false)}
                   buttonText={"확인"}
                   onOk={() => setErrorAlertOpen(false)}
                   text={"본인의 닉네임을 입력하셔야 탈퇴 처리가 가능합니다."}/>
    </section>
  )
}