/* eslint-disable */
import "../../assets/styles/booking.scss";
import {ReactComponent as CloseIcon} from "../../assets/icons/close.svg";
import ModalDialog from "../../components/common/Modal";
import Button from "../../components/common/Button";
import {useCallback, useEffect, useRef, useState} from "react";
import Checkbox from "../../components/common/Checkbox";
import SeatMapImage from "../../assets/images/Map2.png";
import {useLocation, useNavigate} from "react-router";
import {ReactComponent as CalendarIcon} from "../../assets/icons/vuesax/outline/calendar-1.svg";
import {ReactComponent as CouponIcon} from "../../assets/icons/vuesax/outline/receipt-disscount.svg";
import {isValid, format, isWithinInterval, isWeekend} from "date-fns";
import {ReactComponent as MinusIcon} from "../../assets/icons/minus-icon.svg";
import {ReactComponent as PlusIcon} from "../../assets/icons/plus-icon.svg";
import {useContext} from "react";
import {FrontLayerContext} from "../../context/FrontLayerContext";
import {TextField} from "../../components/common/Input";
import {AccordionBox} from "../../components/common/Accordion";
import SeatMap from "./seats";
import ToastAlert from "../../components/common/Toast";
import React from "react";
import {ReactComponent as ArrowIcon} from "../../assets/icons/arrow_right.svg";
import { AuthContext } from "../../context/AuthContext";
import useSWR from "swr";


// 티켓 예약 화면
export default function TicketBookingPage() {

  const navigate = useNavigate();
  const {state} = useLocation();
  const [selectedTab, setSelectedTab] = useState(state?.tab ?? 2);
  const [readTermsOpen, setReadTermsOpen] = useState(selectedTab === 2 && !state.modify);

  const [selectedSeats, setSelectedSeats] = useState([]);
  const [selectSeatAlert, setSelectSeatAlert] = useState(false);
  const [enteredData, setEnteredData] = useState(state);


  useEffect(() => {
    document.getElementsByClassName("page-container")[0]?.scrollTo(0, 0);
  }, []);

  if (!state) {
    return ""
  }

  return (
    <section className="ticket-booking-page">
      <div>
        <h1>티켓 예약하기</h1>
        <CloseIcon onClick={() => navigate("/pool")}/>
      </div>
      <div className="booking-tabs">
        <div className={selectedTab === 1 ? "tab-selected" : ""}>날짜/인원
          <div/>
        </div>
        <div className={selectedTab === 2 ? "tab-selected" : ""}>평상선택
          <div/>
        </div>
        <div className={selectedTab === 3 ? "tab-selected" : ""}>정보입력</div>
      </div>

      {selectedTab === 1 && <TimeSelection setSelectedTab={setSelectedTab} state={enteredData} setEnteredData={setEnteredData}/>}
      {selectedTab === 2 && <SeatSelection state={state.modify ? state.props : enteredData}
                                           selectedSeats={selectedSeats}
                                           setSelectedTab={setSelectedTab}
                                           setSelectSeatAlert={setSelectSeatAlert}
                                           setSelectedSeats={setSelectedSeats}/>}
      {selectedTab === 3 && <InfoInput setSelectedTab={setSelectedTab} state={enteredData} selectedSeats={selectedSeats} checked={state.checked}/>}

      <ModalDialog title={"예약 시 유의사항"}
                   open={readTermsOpen}>
        <div className="booking-agreement">
          <ul>
            <li>평상 예매 완료 후 <b>1시간 이내로 계좌이체를 완료</b>해주셔야 합니다. (시간 내 미입금시 예약 취소)</li>
            <li>계좌 이체 시 필히 <b>입금자명 뒤에 핸드폰 뒷 네 자리를 기입</b>해주세요 (예: 히든이1234). 미기입 시, 동명이인으로 인한 예약 확인 문제 발생 가능. (예약자명과 입금자명이 상이할 경우 010-2138-3563(복사하기)으로 문자 남겨주세요~).</li>
            <li><b>예약 내용과 예약금을 확인하여 일치하면 예약 확정 문자</b>가 발송돼요. (시스템 오류로 확정 문자를 못 받으실 경우, 예약 내용과 예약금이 불일치 할 경우 예약 확정이 불가능 해요.)</li>
            <li>확정이 불가능할 경우 따로 안내를 드릴 예정이오니 안내에 따라 예약해주면 돼요.</li>
            <li>입장료는 평상비와 별도로 당일 현장 결제하시면 돼요.</li>
          </ul>
        </div>
        <Checkbox label={"위 내용을 모두 확인하였습니다."}/>
        <Button style={{marginTop: 20}} onClick={() => setReadTermsOpen(false)}>동의하고 예매하기</Button>
      </ModalDialog>

      <ToastAlert show={selectSeatAlert} onClose={setSelectSeatAlert} text={`평상 ${enteredData.benchCount}개의 위치를 선택해주세요.`} />
    </section>
  )
}

// ==== TABS ====
function SeatSelection(props) {

  const {state, selectedSeats, setSelectedTab, setSelectSeatAlert, setSelectedSeats} = props;
  const totalSeats = state.benchCount;

  // indicator (selected area on the minimap) x and y position
  const [indX, setIndX] = useState(0);
  const [indY, setIndY] = useState(0);

  const shouldScroll = useRef(true);
  const navigate = useNavigate();
  const { API_SERVER_DOMAIN, useAccessToken } = useContext(AuthContext);
  const token = useAccessToken();

  useEffect(() => {
    const miniMap = document.getElementById("miniMap");
    const canvas = document.getElementById("canvas");

    // move minimap indicator on mouse down event and scroll on canvas area
    function handlerIndicator(event) {
      if (event.which === 1) {

        let miniMapOffsetX = event.clientX - miniMap.getBoundingClientRect().x; // pixels from the left side to mouse point
        let miniMapOffsetY = event.clientY - miniMap.getBoundingClientRect().y; // pixels from top to mouse point

        let xPercent = miniMapOffsetX / miniMap.offsetWidth; // percentage from the left
        let yPercent = miniMapOffsetY / miniMap.offsetHeight; // percentage from top

        let scrollLeft = canvas.scrollWidth * xPercent;
        let scrollTop = canvas.scrollHeight * yPercent;

        // 25px as half of the indicator's width
        if (miniMapOffsetX > 25 && miniMapOffsetX < (miniMap.offsetWidth - 25)) {
          setIndX(miniMapOffsetX - 25);
          scrollLeft = scrollLeft - 250;
        } else if (miniMapOffsetX <= 25) {
          setIndX(0);
          scrollLeft = 0;
        } else if (miniMapOffsetX >= (miniMap.offsetWidth - 25)) {
          setIndX(miniMap.offsetWidth - 51);
          scrollLeft = canvas.scrollWidth;
        }

        if (miniMapOffsetY > 25 && miniMapOffsetY < (miniMap.offsetHeight - 25)) {
          setIndY(miniMapOffsetY - 25);
          scrollTop = scrollTop - 250;
        } else if (miniMapOffsetY <= 25) {
          setIndY(0);
          scrollTop = 0;
        } else if (miniMapOffsetY >= (miniMap.offsetHeight - 25)) {
          setIndY(miniMap.offsetHeight - 50);
          scrollTop = canvas.scrollHeight;
        }

        shouldScroll.current = false; // prevent scroll event listener from moving the indicator again
        canvas.scrollTo({left: scrollLeft, top: scrollTop, behavior: "smooth"})
      }
    }

    let timer = null; // used to prevent scroll event function from being called multiple times
    const mapWidth = 1344;
    const mapHeight = 1030;

    function handlerScroll() {
      if (timer !== null) {
        clearTimeout(timer);
      }
      timer = setTimeout(function () {
        if (shouldScroll.current) {
          let xPercent = canvas.scrollLeft / mapWidth;
          let yPercent = canvas.scrollTop / mapHeight;
          setIndX(miniMap.offsetWidth * xPercent + 1.5);
          setIndY(miniMap.offsetHeight * yPercent + 1.5);
        } else {
          shouldScroll.current = true;
        }
      }, 120);
    }

    miniMap.addEventListener("mousedown", handlerIndicator, false);
    canvas.addEventListener("scroll", handlerScroll, false);

    return () => {
      miniMap.removeEventListener("mousedown", handlerIndicator, false);
      canvas.removeEventListener("scroll", handlerScroll, false);
    }

  }, [])

  // maintaining map's 1:1 ratio depending on the screen size
  const [canvasHeight, setCanvasHeight] = useState(500);

  const resizeCanvas = useCallback(() => {
    setCanvasHeight(document.getElementById("canvas")?.offsetWidth ?? 500);
  }, []);

  useEffect(() => {
    setCanvasHeight(document.getElementById("canvas")?.offsetWidth ?? 500);
    window.addEventListener("resize", resizeCanvas)
    return () => {
      window.removeEventListener("resize", resizeCanvas);
    }
  }, [])

  const [isReselecting, setIsReselecting] = useState(false); // 평상 모두 선택한 후 해제하고 다시 선택한다

  function onSelectEnd(selectedSeatCount) {
    if (selectedSeatCount === totalSeats && !isReselecting) {
      let sectionHeight = document.getElementsByClassName("ticket-booking-page")[0].scrollHeight;
      document.getElementsByClassName("page-container")[0]?.scrollTo(0, sectionHeight);
      setIsReselecting(true);
    }
  }

  return (
    <>
      <article>
        <div className="seat-select">
          <h2>평상 위치를 선택하세요({selectedSeats.length}/{totalSeats})</h2>
          <div style={{position: 'relative'}}>
            <img src={SeatMapImage} alt="seat_map" height={126}/>
            <div id={"mapArea"} style={{transform: `translate(${indX}px, ${indY}px)`}}/>
            <div id={"miniMap"} style={{width: 165, height: 126, position: "absolute"}}/>
            <div>
              <div>
                <div className="standard-seat">10</div>
                <span>등받이(O) ({state.pricing.bed[state.timeType].toLocaleString()}원)</span>
              </div>
              <div>
                <div className="bungalow-seat">10</div>
                <span>방갈로형 ({state.pricing.bungalow[state.timeType].toLocaleString()}원)</span>
              </div>
              <div>
                <div className="bench-seat">10</div>
                <span>등받이(X) ({state.pricing.bed[state.timeType].toLocaleString()}원)</span>
              </div>
              <div>
                <div className="bench-seat">10(大)</div>
                <span>등받이(X) ({(state.pricing.bed[state.timeType] * 2).toLocaleString()}원)</span>
              </div>
            </div>
          </div>
        </div>

        <div id="canvas" className="map-screen" style={{height: canvasHeight}}>
          <SeatMap
            seatTotalCount={totalSeats}
            mySeats={state.bedLocation?.split(",").map(no => no.toString().replace(/\W/g, ""))}
            selectedDate={state.selectedDate}
            onSelect={(arr) => {
              setSelectedSeats(arr);
              onSelectEnd(arr.length);
            }}/>
        </div>
      </article>
      <div className="page-action-btn">
        {
          state.modify &&
          <>
            <Button className="prev-btn" onClick={() => {navigate("/pool/ticket")}}>변경취소</Button>
            <Button onClick={() => {
              fetch(`${API_SERVER_DOMAIN}/pool/book`, {
                method: "PUT",
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  bookNo: state.bookNo,
                  bedLocation: selectedSeats.map(id => id.toString())
                })
              })
              .then(response => response.json())
              .then(jsonData => {
                if (jsonData.result == "ok") {
                  alert("변경되었습니다.");
                  navigate("/pool/ticket");
                } else {
                  console.error(jsonData);
                }
              });
            }}>
              변경확정
            </Button>
          </>
        }

        {
          !state.modify &&
          <>
            <Button className="prev-btn" onClick={() => setSelectedTab(1)}>이전단계</Button>
            <Button onClick={() => {
              if (selectedSeats.length < totalSeats) {
                setSelectSeatAlert(true)
              } else setSelectedTab(3);
            }}>다음단계</Button>
          </>
        }
      </div>
    </>
  )
}

function TimeSelection({setSelectedTab, state, setEnteredData}) {

  const {API_SERVER_DOMAIN} = useContext(AuthContext);
  const {data: pool} = useSWR(`${API_SERVER_DOMAIN}/pool/info`, url => fetch(url).then(res => res.json()));
  const timeList = [
    {label: "주간", period: "09:00 - 17:00"},
    {label: "야간", period: "17:30 - 21:30"},
    {label: "종일", period: "09:00 - 21:30"},
  ]

  const {
    setOpenCalendar, setOnSelectDate
  } = useContext(FrontLayerContext);

  function updateDate(val) {
    if (isValid(val)) {
      setEnteredData({...state, selectedDate: format(val, "yyyy-MM-dd")});
    }
  }

  function NumberInput({count, changeProp, unit}) {
    return (
      <div>
        <MinusIcon onClick={() => {
          if (count !== 0) {
            setEnteredData(prevState => ({
              ...prevState, [changeProp]: prevState[changeProp] - 1
            }));
          }
        }}/>
        <span><b>{count}</b> {unit}</span>
        <PlusIcon onClick={() => {
          setEnteredData(prevState => ({
            ...prevState, [changeProp]: prevState[changeProp] + 1
          }));
        }}/>
      </div>
    )
  }

  const validateData = priceList => {
    const period = priceList.find(item => isWithinInterval(state.selectedDate, {start: item.beginDt, end: item.endDt}));

    if (state.timeType !== 0 && (period.nightShow === "N" ||
      (period.nightOption === "weekday" && isWeekend(state.selectedDate)) ||
      (period.nightOption === "weekend" && !isWeekend(state.selectedDate)))) {
      alert("야간 오픈 기간이 아니에요. 이용요금 표에서 야간오픈 기간을 확인 후 예약 해 주세요.");
      return;
    }

    const pricing = {
      bed: period.bed,
      bungalow: period.bungalow,
      adult: period.adult,
      junior: period.junior,
    };

    setEnteredData({...state, pricing: pricing});
    setSelectedTab(state.timeType === 1 ? 3 : 2);
  }

  if (!pool) return;

  return (
    <>
      <div className="booking-options">
        <div>
          <h3>평상 예약 선택
            <small className="text-grey">(예약시간 오전 6시 ~ 오후 9시)</small>
          </h3>
        </div>
        <div>
          {timeList.map((el, idx) => (
            <div className={`time-box${state.timeType === idx ? " selected-time" : ""}`}
                 onClick={() => setEnteredData({...state, timeType: idx})}
                 key={el.label}>
              <span>{el.label}</span>
              <span>{el.period}</span>
            </div>
          ))}

          <div onClick={() => {
            setOnSelectDate(() => updateDate);
            setOpenCalendar(true);
          }}
               className="input-field date-picker-component">
            <p>{state.selectedDate ?? "날짜를 선택하세요"}</p>
            <CalendarIcon/>
          </div>

          <div className="booking-number-select">
            <span>평상 <small>(필수구매)</small></span>
            <NumberInput count={state.benchCount} changeProp={"benchCount"} unit={"개"}/>
          </div>
          <div className="booking-number-label">
            <p><b>부가 예약 정보 </b>
              <small>(방문 인원 </small>
              <small className="text-accent">입장료는 현장 결제에요</small>
              <small>)</small>
            </p>
          </div>
          <div className="booking-number-select">
            <span>중학생 ~ 성인</span>
            <NumberInput count={state.adultCount} changeProp={"adultCount"} unit={"인"}/>
            <span>초등학생 까지</span>
            <NumberInput count={state.kidCount} changeProp={"kidCount"} unit={"인"}/>
          </div>
        </div>
      </div>

      <footer className="pool-booking-terms">
        <AccordionBox header="수영장 예약 시 꼭 확인해주세요!">
          <ul>
            <li>평상 예매 완료 후 1시간 이내로 계좌이체를 완료해주셔야 해요.</li>
            <li>계좌 이체 시 필히 입금자명 뒤에 핸드폰 뒷 네 자리를 기입해주세요.(예: 히든이1234) 미기입 시, 동명이인으로 인한 예약 확인문제가 발생할 수 있어요.</li>
            <li>예약자명과 입금자명이 상이할 경우, 010-2138-3563으로 문자 남겨주세요.</li>
            <li>예약 내용과 예약금을 확인하여 일치하면 예약 확정문자가 발송됩니다. 확정 문자를 받지 못하신 경우, 위 번호로 문의주세요.</li>
            <li>예약 내용과 예약금이 불일치할 경우 예약 확정이 불가능합니다. 확정이 불가능할 경우 별도 안내를 드릴 예정이오니 안내에 따라 예약 부탁 드려요.</li>
            <li>입장료는 평상비와 별도로 당일 현장 결제하시면 돼요.</li>
          </ul>
        </AccordionBox>
      </footer>
      <div className="page-action-btn">
        <Button onClick={() => validateData(pool.infos.price)}>
          다음단계
        </Button>
      </div>
    </>
  )
}

function InfoInput({setSelectedTab, state, selectedSeats, checked = []}) {

  const navigate = useNavigate();

  const [checkAll, setCheckAll] = useState(false);
  const [checkCollect, setCheckCollect] = useState(false);
  const [checkProvide, setCheckProvide] = useState(false);

  const [name, setName] = useState(state.name ?? "");
  const [phoneNum, setPhoneNum] = useState(state.phoneNum ?? "");
  const [errors, setErrors] = useState([]);

  const [infoCollectionOpen, setInfoCollectionOpen] = useState(false); // 개인정보 수집
  const [infoProvisionOpen, setInfoProvisionOpen] = useState(false); // 개인정보 제공
  const [errorMessage, setErrorMessage] = useState(false);
  const { API_SERVER_DOMAIN, useAccessToken, fetchWithToken } = useContext(AuthContext);

  const accessToken = useAccessToken();

  const { data: coupon } = useSWR(accessToken && [`${API_SERVER_DOMAIN}/user/coupon`, accessToken], fetchWithToken);


  // 이름, 연락처, 선택된 평상 저장
  state.name = name;
  state.phoneNum = phoneNum;
  state.selectedSeats = selectedSeats;
  
  // 평상 예약 가격
  let seatPrice = 0;
  if (state.timeType === 1) {
    state.selectedSeats = [];
    seatPrice += state.pricing.bed[1] * state.benchCount;
  } else {
    state.selectedSeats.forEach(seat => {
      if (isNaN(seat)) {
        seatPrice += state.pricing.bungalow[state.timeType];
      } else {
        seatPrice += state.pricing.bed[state.timeType];
      }
    });
  }

  // 적용된 쿠폰이 있는 경우
  // 하드코딩: 주간 평상 가격 할인율 1: 100%, 2, 3: 50%
  let dcPrice = 0;
  if (state.itemId && state.couponId) {
    switch (state.itemId) {
      case 1:
        dcPrice = state.pricing.bed[0] * -1;
        break;
      case 2:
      case 3:
        dcPrice = (state.pricing.bed[0] / 2) * -1;
        break;
    }
  }

  // 선택된 시간대 한글표기
  let timeTypeText = "";
  switch (state.timeType) {
    case 1:
      timeTypeText = "야간";
      break;
    case 2:
      timeTypeText = "종일";
      break;
    default:
      timeTypeText = "주간";
      break;
  }

  function onCheckAll(value) {
    setCheckAll(value);
    setCheckCollect(value);
    setCheckProvide(value);
  }

  useEffect(() => {
    if (checkCollect && checkProvide && !checkAll) {
      setCheckAll(true);
    } else if ((!checkCollect || !checkProvide) && checkAll) setCheckAll(false);
  }, [checkCollect, checkProvide]);

  useEffect(() => {
    if (checked[0]) onCheckAll(true);
    if (checked[1]) setCheckCollect(true);
    if (checked[2]) setCheckProvide(true);
  }, [checked]);
  

  function validateForm() {
    if (name.length > 0 && phoneNum.length > 0) {
      fetch(`${API_SERVER_DOMAIN}/pool/book`, {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          reservedTimeType: (state.timeType + 1),
          reservedDate: state.selectedDate,
          adult: state.adultCount,
          junior: state.kidCount,
          bed: state.benchCount,
          bedLocation: [...new Set(state.selectedSeats)],
          useCouponId: state.couponId ?? 0,
          name: name,
          phone: phoneNum,
        }),
      })
      .then(response => response.json())
      .then(jsonData => {
        if (jsonData.result === "ok") {
          navigate("/pool/booking/success", { replace: true, state: { paymentAmount: seatPrice + dcPrice, accBookingCount: jsonData.accBookingCount, reservedDate: state.selectedDate }});
        } else {
          setErrorMessage(jsonData.message);
        }
      });
    } else {
      if (name.length === 0) {
        setErrors(prev => [...prev, "name"]);
        setErrorMessage("예약자 성함을 입력해 주세요.");
        return;
      }

      if (phoneNum.length === 0) {
        setErrors(prev => [...prev, "phoneNum"]);
        setErrorMessage("예약자의 연락처를 입력해 주세요.");
        return;
      }
    }
  }

  if (!coupon) return;
  // 수영장 사용가능 쿠폰
  const couponList = coupon.infos.filter(c => (c.type === "수영장" && c.status === "AVAILABLE"));

  return (
    <>
      <article className="info-enter-page">
        <div>
          <h2 style={{marginBottom: 16}}>예매자 정보를 입력하세요.</h2>
          <TextField id={"예매자"} label={"예매자"} style={{marginBottom: 16}}
                     value={name} error={errors.includes("name")}
                     defaultValue={name}
                     onChange={(value) => {
                       setName(value);
                       setErrors(prev => prev.filter(el=>el!=="name"));
                     }}
                     helperText={"*예매자 성명은 입금자와 동일하게 입력해주세요."}
                     placeholder={"성명을 입력해주세요."}/>
          <TextField id={"연락처"} label={"연락처"}
                     value={phoneNum} error={errors.includes("phoneNum")}
                     defaultValue={phoneNum}
                     onChange={(value) => {
                       setPhoneNum(value);
                       setErrors(prev => prev.filter(el=>el!=="phoneNum"));
                     }}
                     helperText={"*반드시 예매자 본인의 연락처를 입력해주세요."}
                     placeholder={"연락처를 입력해주세요."}/>
        </div>
        <br/>
        <div className="privacy-terms">
          <h2 style={{marginBottom: 16}}>개인정보 수집, 제공에 동의해주세요.</h2>
          <Checkbox label={"모두 동의합니다."} checked={checkAll} onClick={onCheckAll}/>
          <div>
            <Checkbox label={"(필수) 개인정보 수집 동의"} checked={checkCollect} onClick={setCheckCollect}/>
            <ArrowIcon onClick={() => setInfoCollectionOpen(true)}/>
          </div>
          <div>
            <Checkbox label={"(필수) 개인정보 제공 동의"} checked={checkProvide} onClick={setCheckProvide}/>
            <ArrowIcon onClick={() => setInfoProvisionOpen(true)}/>
          </div>
        </div>
        <br/>
        <div className="payment-details">
          {
            couponList.length > 0 &&
            <button type="button" className="coupon-btn"
                    onClick={() => navigate("/pool/coupon", {state: {...state, checked: [checkAll, checkCollect, checkProvide]}})}>
              <CouponIcon height={20}/>
              <span>쿠폰적용</span>
              <div className="has-coupon-mark"/>
            </button>
          }
          <span className="payment-title text-accent" style={{fontSize: 18, fontWeight: 'bold'}}>평상 예약 결제</span>
          <span className="price">{seatPrice.toLocaleString()}<span>원</span></span>
          <p>예약자: {name}</p>
          <dl>
            <dt>평상구매(일반형, {timeTypeText}) {state.benchCount}</dt>
            <dd>{seatPrice.toLocaleString()}원</dd>
            {dcPrice < 0 && 
              <>
                <dt>쿠폰할인</dt>
                <dd>{dcPrice.toLocaleString()}원</dd>
              </>
            }
            <dt>총 금액</dt>
            <dd>{(seatPrice + dcPrice).toLocaleString()}원</dd>
          </dl>

          <span className="payment-title">입장료 (현장 결제)</span>
          <span className="price">{(state.adultCount * state.pricing.adult[state.timeType] + state.kidCount * state.pricing.junior[state.timeType]).toLocaleString()}<span>원</span></span>
          <p>예약자: {name}</p>
          <dl>
            <dt>대인({timeTypeText}) {state.adultCount}</dt>
            <dd className="text-grey">{(state.adultCount * state.pricing.adult[state.timeType]).toLocaleString()}원</dd>
            <dt>소인({timeTypeText}) {state.kidCount}</dt>
            <dd className="text-grey">{(state.kidCount * state.pricing.junior[state.timeType]).toLocaleString()}원</dd>
            <dt>현장에서 결제할 예상 입장료</dt>
            <dd className="text-grey">{(state.adultCount * state.pricing.adult[state.timeType] + state.kidCount * state.pricing.junior[state.timeType]).toLocaleString()}원</dd>
          </dl>
        </div>
      </article>
      <div className="page-action-btn">
        <Button className="prev-btn" onClick={() => setSelectedTab(state.timeType == 1 ? 1 : 2)}>이전단계</Button>
        <Button disabled={!checkAll} onClick={validateForm}>예약완료</Button>
      </div>

      <ToastAlert show={!!errorMessage} onClose={setErrorMessage} text={errorMessage} />

      <ModalDialog open={infoCollectionOpen}
                   title={"개인정보 수집 동의"}
                   onCancel={() => setInfoCollectionOpen(false)}>
        <div className="personal-info-policy">
          <ol>
            <li>기본수집항목: [필수] 이름, (휴대)전화번호,
              [선택] 이메일 주소 </li>
            <span>※ 추가 수집하는 필수항목</span>
            <ul>
              <li>배송, 방문 등이 필요한 상품 구매 시 : 주소</li>
              <li>해외 여행 관련 상품 구매 시 : 여권상 영문명, 여권번호 끝 4자리, 성별, 생년월일, 이메일 주소, 카카오톡ID, 동행 아동정보(여권상 영문명, 생년월일, 신장)</li>
              <li>병원을 이용하는 경우: 생년월일 (병원 재진 시 이전 진료기록 조회를 위해 예약자명, 생년월일, 전화번호가 수집될 수 있습니다.)</li>
              <li>자동차 검사소 예약 시 : 차량번호, [선택] (사회적약자 수수료 감면 대상인 경우)기초 생활대상자, 국가유공자, 한부모가족, 다자녀, 교통사고지원가족, 교통안전의인 중 해당 여부</li>
            </ul>
            <li>수집 및 이용목적 : 사업자회원과 예약이용자 의 원활한 거래 진행, 고객상담, 불만처리 등 민원 처리, 분쟁조정 해결을 위한 기록보존</li>
            <li>보관기간</li>
              <ul>
                <li>회원탈퇴 시 지체없이 파기</li>
                <li>단, 관련 법령에 의하여 일정 기간 보관이 필요한 경우에는 해당 기간 동안 보관함</li>
              </ul>
            <li>동의 거부권 등에 대한 고지: 정보주체는 개인 정보의 수집 및 이용 동의를 거부할 권리가 있으나,
              이 경우 상품 및 서비스 예약이 제한될 수 있습니다. 그 밖의 내용은 네이버 개인정보 처리방침을 따릅니다.</li>
          </ol>
          <Checkbox label={"위 내용을 모두 확인하였습니다."}/>
        </div>
        <Button onClick={() => {
          setCheckCollect(true);
          setInfoCollectionOpen(false)
        }}>동의</Button>
      </ModalDialog>
      <ModalDialog open={infoProvisionOpen}
                   title={"개인정보 제공 동의"}
                   onCancel={() => setInfoProvisionOpen(false)}>
        <div className="personal-info-policy">
          <p style={{marginBottom: 20}}>&lt;개인정보 제공 동의&gt;</p>
          <ol>
            <li>개인정보를 제공받는 자 : 야옹상회</li>
            <li>제공하는 기본 개인정보 항목 : [필수] 이름, (휴대)전화번호</li>
            <span>※ 추가 제공하는 필수항목</span>
            <ul>
              <li>배송, 방문 등이 필요한 상품 구매 시 : 주소</li>
              <li>해외 여행관련 상품 구매 시 에 한해, 여권상 영문명, 생년월일, 성별, 이메일주소, 카카오톡ID, 동행 아동정보 ( 여권상 영문명, 생년월일, 신장)를 추가 수집), 이메일 주소</li>
              <li>병원을 이용하는 경우: 생년월일 (병원 재진 시 이전 진료기록 조회를 위해 예약자명, 생년월일, 전화번호가 수집될 수 있습니다.)</li>
            </ul>
            <li>개인정보를 제공받는 자의 이용목적 : 사업자 회원과 예약이용자의 원활한 거래 진행, 서비스 분석과 통계에 따른 혜택 및 맞춤 서비스 제공,
              민원처리 등 고객상담, 고객관리,서비스 이용에 따른 설문조사 및 혜택 제공, 분쟁조정을 위한 기록보존</li>
            <li>개인정보를 제공받는 자의 개인정보 보유 및 이용기간 : 야옹상회 회원탈퇴 시 또는 위 개인정보 이용목적 달성 시 까지 이용합니다. (단, 관련 법령에 따른 보관의무가 있는 경우에 그 기간동안 보관)</li>
            <li>동의 거부권 등에 대한 고지 : 정보주체는 개인정보 제공 동의를 거부할 권리가 있으나, 이 경우 상품 및 서비스 예약이 제한될 수 있습니다.</li>
          </ol>
          <Checkbox label={"위 내용을 모두 확인하였습니다."}/>
        </div>
        <Button onClick={() => {
          setCheckProvide(true);
          setInfoProvisionOpen(false)
        }}>동의</Button>
      </ModalDialog>
    </>
  )
}