import "../../assets/styles/pool.scss";
import {useContext, useState, useEffect} from "react";
import {ReactComponent as LocationIcon} from "../../assets/icons/vuesax/bold/location.svg";
import {ReactComponent as CategoryIcon} from "../../assets/icons/vuesax/bold/category.svg";
import {ReactComponent as CallIcon} from "../../assets/icons/vuesax/bold/call.svg";
import {ReactComponent as CalendarIcon} from "../../assets/icons/vuesax/outline/calendar-1.svg";
import {ReactComponent as LinkIcon} from "../../assets/icons/vuesax/outline/link-2.svg";
import {ReactComponent as TicketIcon} from "../../assets/icons/vuesax/outline/ticket.svg";
import {ReactComponent as TicketRedDotIcon} from "../../assets/icons/vuesax/outline/ticket-reddot.svg";
import {ReactComponent as DuplicateIcon} from "../../assets/icons/duplicate.svg";
import {ReactComponent as MinusIcon} from "../../assets/icons/minus-icon.svg";
import {ReactComponent as PlusIcon} from "../../assets/icons/plus-icon.svg";
import {ReactComponent as DiscountIcon} from "../../assets/icons/receipt-disscount.svg";
import {ReactComponent as InfoIcon} from "../../assets/icons/information.svg";
import {ReactComponent as TicketSolidIcon} from "../../assets/icons/vuesax/bold/ticket.svg";
import {ReactComponent as ClockIcon} from "../../assets/icons/vuesax/bold/clock.svg";
import {ReactComponent as CardIcon} from "../../assets/icons/vuesax/bold/card.svg";
import {ReactComponent as RatingIcon} from "../../assets/icons/rating.svg";
import {ReactComponent as TagIcon} from "../../assets/icons/vuesax/bold/tag.svg";
import {ReactComponent as AppleStoreLogo} from "../../assets/images/apple_store.svg";
import {ReactComponent as GooglePlayLogo} from "../../assets/images/google_play.svg";
import {ReactComponent as SwiperArrow} from "../../assets/icons/swiper_arrow.svg";
import {FrontLayerContext} from "../../context/FrontLayerContext";
import {isValid, format, isWithinInterval, isWeekend} from "date-fns";
import Button from "../../components/common/Button";
import BenchNoBack from "../../assets/images/Property 1=평상1.png";
import BenchWithBack from "../../assets/images/Property 1=평상2.png";
import Bungalow from "../../assets/images/Property 1=방갈로.png";
import LuckySpin from "../../assets/images/Lucky Spin.png";
import SlideOne from "../../assets/images/pool-slide-1.png";
import SlideTwo from "../../assets/images/pool-slide-2.png";
import SlideThree from "../../assets/images/pool-slide-3.png";
import SlideFour from "../../assets/images/pool-slide-4.png";
import Loading from "../../assets/icons/Loading.png";
import ToryPopup from "../../assets/images/tory-popup@2x.png";
import {useRunTimeEnvironment} from "../../utils/hooks";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import {useNavigate} from "react-router";
import {AccordionBox} from "../../components/common/Accordion";
import ToastAlert from "../../components/common/Toast";
import useSWR from 'swr';
import {AuthContext} from "../../context/AuthContext";
import {useScript} from "../../utils/hooks";
import summonFriends from "../../services/summoner";
import ModalDialog from "../../components/common/Modal";


// 인스타그램 및 네이버 블로그 링크
const NAVER_LINK = "https://map.naver.com/p/entry/place/1294395970?c=15.00,0,0,0,dh&placePa%20th=/review";
const INSTA_LINK = "https://www.instagram.com/hiddenvalley_songchu";


const tagList = ["취사가능", "송추IC_1분", "재방문많은", "친절", "안전한"];
const tagList2 = ["취사가능수영장", "야외수영장", "물놀이장", "경기도수영장", "워터파크"];
const timeList = [
  {label: "주간", period: "09:00 - 17:00"},
  {label: "야간", period: "17:30 - 21:30"},
  {label: "종일", period: "09:00 - 21:30"},
];

export default function SwimmingPoolPage({lastViewedSlide}) {

  const { API_SERVER_DOMAIN, useAccessToken, fetchWithToken } = useContext(AuthContext);
  const { data } = useSWR(`${API_SERVER_DOMAIN}/pool/info`, url => fetch(url).then(res => res.json()));

  const [pricingList, setPricingList] = useState([]);
  let pricingListArr = [];
  const [priceSwiper, setPriceSwiper] = useState();
  const [initialPriceSlide, setInitialPriceSlide] = useState(0);
  const [activePriceSlide, setActivePriceSlide] = useState(0);
  const [alertOpen, setAlertOpen] = useState(false);
  const [toryPopupOpen, setToryPopupOpen] = useState(false);

  useEffect(() => {
    if (data) {
      data.infos?.price.forEach((price) => {
        pricingListArr.push({
          "평상": {weekday: price.bed[0], night: price.bed[1], allDay: price.bed[2]},
          "방갈로": {weekday: price.bungalow[0], night: price.bungalow[1], allDay: price.bungalow[2]},
          "중학생~성인": {weekday: price.adult[0], night: price.adult[1], allDay: price.adult[2]},
          "초등학생 까지": {weekday: price.junior[0], night: price.junior[1], allDay: price.junior[2]},
        })
      })
      setInitialPriceSlide(data.infos?.price.findIndex(p => isWithinInterval(new Date(), {start: p.beginDt, end: p.endDt})));
      setPricingList(pricingListArr);
    }
  }, [data]);

  function getOptionName(option) {
    switch (option) {
      case "weekday": return "평일만";
      case "weekend": return "주말만";
      case "wholeWeek": return "평일 & 주말";
      default: return "";
    }
  }

  const {
    setOpenCalendar, setOnSelectDate
  } = useContext(FrontLayerContext);

  const RTE = useRunTimeEnvironment();
  const navigate = useNavigate();

  const [selectedTime, setSelectedTime] = useState(0);
  const [selectedDate, setSelectedDate] = useState(null);

  const [benchCount, setBenchCount] = useState(0); // 평상
  const [adultCount, setAdultCount] = useState(0); // 중학생, 성인
  const [kidCount, setKidCount] = useState(0); // 초등학생

  const [message, setMessage] = useState(false);
  const accessToken = useAccessToken();

  const { data: ticketData } = useSWR(accessToken && [`${API_SERVER_DOMAIN}/pool/book?isActive=true`, accessToken], fetchWithToken);
  const { data: profile } = useSWR(accessToken && [`${API_SERVER_DOMAIN}/user/profile/info`, accessToken], fetchWithToken);

  function updateDate(val) {
    if (isValid(val)) {
      setSelectedDate(format(val, "yyyy-MM-dd"));
    }
  }

  function NumberInput({count, onChangeCount, unit}) {
    return (
      <div>
        <MinusIcon onClick={() => {
          if (count !== 0) onChangeCount((prev) => prev - 1)
        }}/>
        <span><b>{count}</b> {unit}</span>
        <PlusIcon onClick={() => onChangeCount((prev) => prev + 1)}/>
      </div>
    )
  }

  function validateData(priceList) {
    const now = new Date();
    if (now.getHours() < 6 || now.getHours() >= 21) {
      setAlertOpen(true);
      return;
    }

    if (!selectedDate) {
      setMessage("날짜를 선택해 주세요.");
      return;
    }

    /* if (adultCount === 0 && kidCount === 0) {
      setMessage("인원을 선택해 주세요.");
      return;
    } */

    if (selectedTime !== 1 && benchCount === 0) {
      setMessage("사용할 평상 개수를 선택해 주세요.");
      return;
    }

    const period = priceList.find(item => isWithinInterval(selectedDate, {start: item.beginDt, end: item.endDt}));

    if (selectedTime !== 0 && (period.nightShow === "N" ||
      (period.nightOption === "weekday" && isWeekend(selectedDate)) ||
      (period.nightOption === "weekend" && !isWeekend(selectedDate)))) {
      alert("야간 오픈 기간이 아니에요. 이용요금 표에서 야간오픈 기간을 확인 후 예약 해 주세요.");
      return;
    }

    const pricing = {
      bed: period.bed,
      bungalow: period.bungalow,
      adult: period.adult,
      junior: period.junior,
    };

    const context = {
      timeType: selectedTime,
      selectedDate: selectedDate,
      adultCount: adultCount,
      kidCount: kidCount,
      benchCount: benchCount,
      tab: selectedTime === 1 ? 3 : 2,
      pricing: pricing
    }
    navigate("/pool/booking", { state: context });
  }

  useEffect(() => {
    document.getElementsByClassName("page-container")[0]?.scrollTo(0, 0);

    // 토리 게임 링크 팝업
    const today = format(new Date(), "yyyy-MM-dd");
    const storedDate = localStorage.getItem("toryPopup");
    if (!storedDate || storedDate !== today) {
      setToryPopupOpen(true);
    }
  }, []);

  useScript("https://t1.kakaocdn.net/kakao_js_sdk/2.7.1/kakao.min.js");

  if (!data || !ticketData || !profile) {
    return (
      <div className="loading-circle">
        <img src={Loading} alt="loading" height={24}/>
      </div>
    )
  }

  return (
    <>
      <section className="swimming-pool-page">
        <div className="pool-images">
          <div className="fade-bg" />
          <div>
            <h1>{data.infos.title}</h1>
            <p>양주시 실외 수영장</p>
            <div>
              {tagList.map((t) => (
                <div key={t} className="pool-tag"># {t}</div>
              ))}
            </div>
          </div>
          <img src={LuckySpin} alt="lucky-spin" onClick={() => navigate("/pool/lucky-spin")}/>
          {/* <img src={LuckySpin} alt="lucky-spin" onClick={() => setMessage("럭키 스핀! 곧 오픈 하니 기다려 주세요!")}/> */}
          <TicketSolidIcon onClick={() => navigate("/pool/ticket")}/>
          {
            ticketData.infos?.length > 0 && 
            <>
              <div className="new-ticket-mark"><span>{ticketData.infos.length}</span></div>
              <div className="new-ticket-msg" onClick={() => navigate("/pool/ticket")}><span>예약내역을 확인하세요</span></div>
            </>
          }
        </div>
        <article className="pool-description">
          <div>
            <p>
              <LocationIcon height={16} width={16}/>
              {data.infos.address}
              <span onClick={() => {navigator.clipboard.writeText(data.infos.address)}} style={{cursor: 'pointer'}}>
                주소복사 <DuplicateIcon style={{marginLeft: 2}}/>
              </span>
            </p>
            <p><CategoryIcon height={16} width={16}/>{data.infos.summary}</p>
            <p><CallIcon height={16} width={16}/>010-2138-3563</p>
          </div>

          <div className="booking-options">
            <div>
              <h3>평상 예약 선택
                <small className="text-grey">(예약시간 오전 6시 ~ 오후 9시)</small>
              </h3>
            </div>

            <div>
              {timeList.map((el, idx) => (
                <div className={`time-box${selectedTime === idx ? " selected-time" : ""}`}
                    onClick={() => setSelectedTime(idx)}
                    key={el.label}>
                  <span>{el.label}</span>
                  <span>{el.period}</span>
                </div>
              ))}

              <div onClick={() => {
                    setOnSelectDate(() => updateDate);
                    setOpenCalendar(true);
                  }}
                  className="input-field date-picker-component">
                {/* zIndex: 사파리 렌더링 문제 수정 */}
                <p style={{ "zIndex": 1 }}>{selectedDate ?? "날짜를 선택하세요"}</p>
                <CalendarIcon/>
              </div>

              <div className="booking-number-select">
                <span>평상 <small>(필수구매)</small></span>
                <NumberInput count={benchCount} onChangeCount={setBenchCount} unit={"개"}/>
              </div>
              <div className="booking-number-label">
                <p><b>부가 예약 정보 </b>
                  <small>(방문 인원 </small>
                  <small className="text-accent">입장료는 현장 결제에요</small>
                  <small>)</small>
                </p>
              </div>
              <div className="booking-number-select">
                <span>중학생 ~ 성인</span>
                <NumberInput count={adultCount} onChangeCount={setAdultCount} unit={"인"}/>
                <span>초등학생 까지</span>
                <NumberInput count={kidCount} onChangeCount={setKidCount} unit={"인"}/>
              </div>
            </div>

            <div className="discount-coupons">
              <div>
                <div>
                  <DiscountIcon />
                </div>
                <p>만 24개월 미만 무료입장, 증빙서류 지참 꼭 해주세요.</p>
              </div>
              <div>
                <div>
                  <InfoIcon />
                </div>
                <p>평상비만 계좌이체. 입장료는 현장에서 결제하시면 돼요.</p>
              </div>
            </div>

            <Button onClick={() => validateData(data.infos.price)}>다음단계</Button>
          </div>

          <div className="link-btn">
            <button type="button" onClick={() => summonFriends(1, profile.info.refCode)}>
              <LinkIcon height={20}/>친구소환
            </button>
            <button type="button" onClick={() => navigate("/pool/ticket")}>
              {ticketData.infos?.length === 0 && <TicketIcon height={20}/>}
              {ticketData.infos?.length > 0 && <TicketRedDotIcon style={{marginTop: "1px", marginRight: "2px"}} height={20}/>}예약티켓
            </button>
          </div>

          <hr style={{background: "#f2f2f2", height: 12, width: '100%', border: 'none'}} />

          <div className="pool-detail">
            {pricingList.length > 0 &&
              <Swiper pagination={true} spaceBetween={16}
                      onSwiper={setPriceSwiper}
                      initialSlide={lastViewedSlide.current ?? initialPriceSlide}
                      onSlideChange={({activeIndex}) => {
                        setActivePriceSlide(activeIndex);
                        lastViewedSlide.current = activeIndex;
                      }}
                      className="pool-pricing-swiper">
                <h3>이용 요금</h3>
                {pricingList.map((price, idx) => (
                  <SwiperSlide key={"price-slide-"+idx}>
                    <div className="pricing-dates">
                      <p>{data.infos.price[idx].beginDt} ~ {data.infos.price[idx].endDt}</p>
                      <div/>
                      {data.infos.price[idx].nightShow === "Y" ?
                        <p>야간 : {getOptionName(data.infos.price[idx].nightOption)} 예약 가능</p> :
                        <p>야간 : 예약 불가</p>
                      }
                    </div>

                    <table>
                      <thead>
                      <tr>
                        <th style={{textAlign: 'start'}}>구분</th>
                        <th>주간</th>
                        <th>야간</th>
                        <th>종일</th>
                      </tr>
                      </thead>
                      <tbody>
                      {Object.keys(price).map((item) => (
                        <tr key={"요금-" + item}>
                          <td>{item}</td>
                          <td style={{textAlign: "center"}}>{price[item].weekday.toLocaleString()}</td>
                          <td style={{textAlign: "center"}}>{data.infos.price[idx].nightShow === "Y" ? price[item].night.toLocaleString() : "-"}</td>
                          <td style={{textAlign: "center"}}>{data.infos.price[idx].nightShow === "Y" ? price[item].allDay.toLocaleString() : "-"}</td>
                        </tr>
                      ))}
                      </tbody>
                    </table>
                  </SwiperSlide>
                ))}
                {activePriceSlide !== 0 && <SwiperArrow className={"swiper-left-btn"} onClick={() => priceSwiper?.slidePrev()}/>}
                {activePriceSlide + 1 < pricingList.length && <SwiperArrow className={"swiper-right-btn"} onClick={() => priceSwiper?.slideNext()}/>}
              </Swiper>
            }

            <h3>평상 유형</h3>
            <div className="bench-images">
              <div style={{backgroundImage: `url("${BenchWithBack}")`}} />
              <div style={{backgroundImage: `url("${BenchNoBack}")`}} />
              <div style={{backgroundImage: `url("${Bungalow}")`, gridRow: '1 / 3', gridColumn: 2}} />
            </div>
            <div className="bench-types">
              <p><span>1</span>평상형(등받이 있음)</p>
              <p><span>2</span>평상형(등받이 없음)</p>
              <p><span>3</span>방갈로형</p>
            </div>

            <h3>시설 안내</h3>

            <div className="facilities-images">
              <Swiper pagination={true} modules={[Pagination]}
                      spaceBetween={4}
                      className="pool-img-swiper">
                <SwiperSlide>
                  <img src={SlideOne} alt="pool-facilities-1"/>
                </SwiperSlide>
                <SwiperSlide>
                  <img src={SlideTwo} alt="pool-facilities-2"/>
                </SwiperSlide>
                <SwiperSlide>
                  <img src={SlideThree} alt="pool-facilities-3"/>
                </SwiperSlide>
                <SwiperSlide>
                  <img src={SlideFour} alt="pool-facilities-4"/>
                </SwiperSlide>
              </Swiper>
            </div>

            <dl className="facility-description">
              <dt><ClockIcon/></dt>
              <dd>영업중 ・ 21:30에 영업 종료</dd>

              <dt><CategoryIcon/></dt>
              <dd>남・여 화장실 구분, 단체이용 가능, 예약, 주차</dd>

              <dt><CardIcon/></dt>
              <dd>지역화폐(카드)</dd>

              <dt><RatingIcon/></dt>
              <dd>
                <a href={NAVER_LINK}>네이버 리뷰 보러가기 →</a>
              </dd>

              <dt><LinkIcon/></dt>
              <dd style={{gap: 0}}>
                Instagram (<a href={INSTA_LINK}>hiddenvalley_songchu</a>)
              </dd>

              <dt><TagIcon/></dt>
              <dd>
                {tagList2.map((tag) => (
                  <span key={"dd-tag-"+tag}>{tag}</span>
                ))}
              </dd>
            </dl>
          </div>

          {RTE !== "APP" &&
            <div className="download-btn">
              <a href="https://apps.apple.com/kr/app/%EC%95%BC%EC%98%B9%EC%83%81%ED%9A%8C-%EC%89%BD%EA%B3%A0-%EC%A6%90%EA%B1%B0%EC%9A%B4-%ED%8F%AC%EC%9D%B8%ED%8A%B8-%EB%A6%AC%EC%9B%8C%EB%93%9C%EC%95%B1-%EC%95%B1%ED%85%8C%ED%81%AC/id6502283766" target="_blank">
                <AppleStoreLogo/>
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.yaong.app" target="_blank">
                <GooglePlayLogo/>
              </a>
            </div>
          }

          <footer className="pool-booking-terms">
            <AccordionBox header="수영장 예약 시 꼭 확인해주세요!">
              <ul>
                <li>평상 사이즈는 가로, 세로 약 2m로 4~6인 가족이 이용하시기에 적합하게 구성되었어요. 사이즈 감안하셔서 평상 개수 예약해 주시면 돼요.</li>
                <li>평상 예매 완료 후 1시간 이내로 계좌이체를 완료해주셔야 해요.</li>
                <li>계좌 이체 시 필히 입금자명 뒤에 핸드폰 뒷 네 자리를 기입해주세요.(예: 히든이1234) 미기입 시, 동명이인으로 인한 예약 확인문제가 발생할 수 있어요.</li>
                <li>예약자명과 입금자명이 상이할 경우, 010-2138-3563으로 문자 남겨주세요.</li>
                <li>예약 내용과 예약금을 확인하여 일치하면 예약 확정문자가 발송됩니다. 확정 문자를 받지 못하신 경우, 위 번호로 문의주세요.</li>
                <li>예약 내용과 예약금이 불일치할 경우 예약 확정이 불가능합니다. 확정이 불가능할 경우 별도 안내를 드릴 예정이오니 안내에 따라 예약 부탁 드려요.</li>
                <li>입장료는 평상비와 별도로 당일 현장 결제하시면 돼요.</li>
                <li>야간 평상은 현장에서 선착순으로 선택해요.</li>
              </ul>
            </AccordionBox>
            <AccordionBox header="주의사항">
              <p><b>안전하고 쾌적한 수영장 이용을 위해 먼저 아래 안내 사항을 꼭 확인해 주세요</b></p>
              <ol>
                <li>수영장 이용 시 머리를 덮는 모자는 필수 착용. (캡 모자, 수영모 등 정수리를 덮는 모자 O / 썬캡, 스포츠 헤어밴드류는 금지)</li>
                <li>유리병, 숯, 캠핑용 가스통 (버너 포함 20cm 이상) 반입 금지</li>
                <li>반짝이(글리터), 동물 튜브 및 120cm 이상 대형 튜브 사용 금지</li>
                <li>수영복 또는 래쉬가드 착용 후 풀장 이용 가능 (청 소재, 물 빠짐이 있는 의류 X)</li>
                <li>아쿠아슈즈 착용 금지 (미취학 아동에 한 해 아쿠아 삭스(바닥이  천 재질)는 착용 가능)</li>
                <li>전 자리 휴대용 가스 부르스타 사용 가능</li>
                <li>전 구역 흡연 금지 - 지정 흡연 구역 이용</li>
                {/* <li>입장 시 평상 1개 당 10L 종량제 봉투를 무상배부. 퇴장 시 쓰레기 및 재활용품은 분리수거장에 직접 분리 배출해 주세요!</li> */}
                <li>수영복, 모자, 튜브 대여 없음.</li>
                <li>돗자리 필수</li>
              </ol>
            </AccordionBox>
            <AccordionBox header="환불규정">
              <ul style={{marginBottom: 27}}>
                <li>당일 취소 및 예약 날짜 변경 불가해요.</li>
                <li>예약일 하루 전까지는 날짜 변경 가능해요.(입장일 24시부터 취소 불가능)</li>
                <li>예약 취소 시, 환불 규정은 아래와 같아요.</li>
                <li>4일 전, 100% 환불</li>
                <li>3일 전, 70% 환불</li>
                <li>2일 전, 50% 환불</li>
                <li>1일 전, 30% 환불</li>
                <li>***당일 취소, 당일 예약 변경 불가***</li>
                <li>우천 시에도 영업해요. 단, 천재지변, 기상악화 등의 이유로 정상 운영이 불가 시, 예약이 취소될 수 있어요. 그럴 경우, 전액 환불 처리해드려요.</li>
                <li>환불 규정에 따라, 환불은 매월 15일, 말일에 일괄 입금 처리돼요.</li>
                <li>취소시, [수영장 &gt; 티켓 &gt; 상단 햄버거 메뉴 &gt; 취소하기]에 환불 받으실 계좌 정보를 기재해 주세요.</li>
              </ul>
            </AccordionBox>
          </footer>
        </article>

      </section>

      <ToastAlert show={!!message} onClose={setMessage} text={message} />

      <ModalDialog
        title="지금은 예약 시간이 아니에요."
        className="alert-modal"
        open={alertOpen}
      >
        <p>예약 가능 시간</p>
        <p className="text-emphasis">오전 6시 ~ 오후 9시</p>
        <Button onClick={() => setAlertOpen(false)}>확인</Button>
      </ModalDialog>
      
      <ModalDialog className="popup-modal" open={toryPopupOpen}>
        <div className="img-container" onClick={() => navigate("/tory-game")}>
          <img src={ToryPopup} alt="토리 게임 링크" />
        </div>
        <div className="buttons">
          <span onClick={() => {
            localStorage.setItem("toryPopup", format(new Date(), "yyyy-MM-dd"));
            setToryPopupOpen(false);
          }}>
            오늘 하루 그만 보기
          </span>
          <div className="line"></div>
          <span onClick={() => setToryPopupOpen(false)}>닫기</span>
        </div>
      </ModalDialog>
    </>
  )
}