import {ReactComponent as ArrowIcon} from "../../assets/icons/arrow_left.svg";
import React, {useState, useEffect, useContext} from "react";
import {useNavigate} from "react-router";
import Button from "../../components/common/Button";
import Stamp1 from "../../assets/images/attendance/Stamp_1.png";
import Stamp2 from "../../assets/images/attendance/Stamp_2.png";
import Stamp3 from "../../assets/images/attendance/Stamp_3.png";
import Stamp4 from "../../assets/images/attendance/Stamp_4.png";
import Stamp5 from "../../assets/images/attendance/Stamp_5.png";
import Stamp6 from "../../assets/images/attendance/Stamp_6.png";
import Stamp7 from "../../assets/images/attendance/Stamp_7.png";
import TodayStamp from "../../assets/images/attendance/Today.png";
import TodayStamp2 from "../../assets/images/attendance/Today_2x.png";
import CheckedStamp from "../../assets/images/attendance/Checked.png";
import CheckedStamp2 from "../../assets/images/attendance/Checked_2x.png";
import RewardVideo from "../../assets/images/Confetti_transparent.webm";
import PointImg from "../../assets/images/point-large.png";
import DoublePointsIcon from "../../assets/images/double-points.png";
import TriplePointsIcon from "../../assets/images/triple-points.png";
import DoublePointCat from "../../assets/images/double-point-cat.png";
import ModalDialog from "../../components/common/Modal";
import ToastAlert from "../../components/common/Toast";
import useSWR from "swr";
import {AuthContext} from "../../context/AuthContext";
import {useRunTimeEnvironment} from "../../utils/hooks";
import {useAdsListener} from "../../services/admob";

export default function AttendancePage() {

  const navigate = useNavigate();
  const RTE = useRunTimeEnvironment();
  const {API_SERVER_DOMAIN, useAccessToken, fetchWithToken} = useContext(AuthContext);
  const accessToken = useAccessToken();
  const {data} = useSWR(accessToken && [`${API_SERVER_DOMAIN}/user/attendance`, accessToken], fetchWithToken);

  const [adConfirmOpen, setAdConfirmOpen] = useState(false);
  const [pointSuccessOpen, setPointSuccessOpen] = useState(false);

  const [rewardPoints, setRewardPoints] = useState(0);
  const [showConfetti, setShowConfetti] = useState(false);

  const [alertMessage, setAlertMessage] = useState(false);
  let is2x = false;

  function spinNumbers(point) {
    const spin = setInterval(() => {
      setRewardPoints(Math.floor(Math.random() * 99) + 1);
    }, 100);
    setTimeout(() => {
      clearInterval(spin);
      setRewardPoints(point);
      setShowConfetti(true);
    }, 1700)
  }

  /* function openRewardPopup() {
    setAdConfirmOpen(false);
    setPointSuccessOpen(true);
    spinNumbers();
  } */

  async function checkAttendance() {
    setAdConfirmOpen(false);

    // 출석체크 하기
    const response = await fetch(`${API_SERVER_DOMAIN}/user/attendance?attend=true`, {
      headers: {
        "Authorization": `Bearer ${accessToken}`,
        "Content-Type": "application/json"
      }
    });
    const jsonData = await response.json();

    // 보상 받기
    const response2 = await fetch(`${API_SERVER_DOMAIN}/user/attendance/reward`, {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${accessToken}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        code: jsonData.code,
        x2: is2x
      })
    });
    const jsonData2 = await response2.json();

    if (jsonData2.result === "ok") {
      setPointSuccessOpen(true);
      spinNumbers(jsonData2.recvPoint);
    } else {
      setAlertMessage(jsonData2.message);
    }
  }

  function checkAttendance2x() {
    if (RTE === "APP") {
      is2x = true;
      window.flutter_inappwebview.callHandler("run_admob", "");
    } else {
      setAlertMessage("광고 시청은 앱에서만 가능해요");
    }
  }

  useAdsListener("", "", {task: "attendance"}, checkAttendance);

  useEffect(() => {
    let pageContainer = document.getElementsByClassName("page-container")[0];
    pageContainer?.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!data || data.result !== "ok") return;

  return (
    <section className="attendance-page">
      <div className="page-header header-shadow">
        <ArrowIcon onClick={() => navigate("/playground")} style={{left: 24}}/>
        <h3>연속 출석 이벤트</h3>
      </div>

      <article>

        <h1>매일매일 야옹 출석하고<br/>포인트 받으세요!</h1>
        <p className="text-light">야옹이는 집사의 출석을 기다린다야옹~</p>

        <div className="attendance-check-box">
          <div>
            {
              data.consecutiveDays > 0 ? 
                <img src={CheckedStamp} alt="day_one" width={80}/> :
                data.consecutiveDays === 0 && !data.alreadyAttendToday ? 
                  <img src={TodayStamp} alt="day_one" width={80}/> :
                  <img src={Stamp1} alt="day_one" width={80}/>
            }
            {
              data.consecutiveDays > 1 ? 
                <img src={CheckedStamp} alt="day_two" width={80}/> :
                data.consecutiveDays === 1 && !data.alreadyAttendToday ? 
                  <img src={TodayStamp} alt="day_two" width={80}/> :
                  <img src={Stamp2} alt="day_two" width={80}/>
            }
          </div>
          <div>
            {
              data.consecutiveDays > 2 ? 
                <img src={CheckedStamp2} alt="day_three" width={80}/> :
                data.consecutiveDays === 2 && !data.alreadyAttendToday ? 
                  <img src={TodayStamp2} alt="day_three" width={80}/> :
                  <img src={Stamp3} alt="day_three" width={80}/>
            }
            {
              data.consecutiveDays > 3 ? 
                <img src={CheckedStamp} alt="day_four" width={80}/> :
                data.consecutiveDays === 3 && !data.alreadyAttendToday ? 
                  <img src={TodayStamp} alt="day_four" width={80}/> :
                  <img src={Stamp4} alt="day_four" width={80}/>
            }
            {
              data.consecutiveDays > 4 ? 
                <img src={CheckedStamp} alt="day_five" width={80}/> :
                data.consecutiveDays === 4 && !data.alreadyAttendToday ? 
                  <img src={TodayStamp} alt="day_five" width={80}/> :
                  <img src={Stamp5} alt="day_five" width={80}/>
            }
          </div>
          <div>
            {
              data.consecutiveDays > 5 ? 
                <img src={CheckedStamp} alt="day_six" width={80}/> :
                data.consecutiveDays === 5 && !data.alreadyAttendToday ? 
                  <img src={TodayStamp} alt="day_six" width={80}/> :
                  <img src={Stamp6} alt="day_six" width={80}/>
            }
            {
              data.consecutiveDays > 6 ? 
                <img src={CheckedStamp2} alt="day_seven" width={80}/> :
                data.consecutiveDays === 6 && !data.alreadyAttendToday ? 
                  <img src={TodayStamp} alt="day_seven" width={80}/> :
                  <img src={Stamp7} alt="day_seven" width={80}/>
            }
          </div>
        </div>
        {
          data.alreadyAttendToday ?
            <Button disabled>받기 완료</Button> :
            <Button onClick={() => setAdConfirmOpen(true)}>출석하고 야옹포인트 받기</Button>
        }
        <div className="attendance-check-info">
          <h4>참여 방법</h4>
          <ul style={{marginBottom: 27}}>
            <li>[출석하고 야옹포인트 받기]를 누르면 야옹이가 7일 연속 랜덤 포인트를 드린다 야옹~</li>
            <li>3일차 이용출석은 2배, 7일차는 3배 포인트를 야옹이가 드릴꺼다냐옹~</li>
            <li>연속 출석을 놓쳐도 1일차부터 다시 시작하면 연속 출석을 다시 받을 수 있다냐옹~</li>
          </ul>
        </div>
      </article>

      <ModalDialog
        className={"ad-confirm-modal"}
        open={adConfirmOpen}
        title={"야옹 출석 이벤트"}
        onCancel = {() => setAdConfirmOpen(false)}
      >
        <p>만나러 와줘서 고맙다야옹~</p>
        <p>광고를 보시면 야옹이가</p>
        <p>2배 포인트를 드린다야옹</p>
        <Button onClick={() => checkAttendance2x()}>
          <img src={DoublePointCat} alt="double-points" height={56}/>
          2배 포인트 받기
        </Button>
        <Button onClick={() => checkAttendance()}>그냥 받기</Button>
      </ModalDialog>

      <ModalDialog
        className={"point-success-modal"}
        open = {pointSuccessOpen}
        title={`${rewardPoints}포인트를 받았어요`}
        image={<img src={data.consecutiveDays === 3 ? DoublePointsIcon : data.consecutiveDays === 7 ? TriplePointsIcon : PointImg} alt="points" height={100}/>}
        onCancel = {() => setPointSuccessOpen(false)}
      >
        <p>{rewardPoints}포인트를 지급해드렸어요.<br/>내일도 야옹이 만나러 오세요!</p>
        <Button disabled={!showConfetti} onClick={() => setPointSuccessOpen(false)}>확인</Button>
      </ModalDialog>

      {pointSuccessOpen && showConfetti &&
        <div id="bg-reward" onClick={() => {
          setShowConfetti(false);
          setPointSuccessOpen(false);
          // 랜덤 행운 페이지로 이동
          navigate("/random-luck");
        }}>
          <video autoPlay muted loop>
            <source src={RewardVideo} type="video/mp4"/>
          </video>
        </div>
      }
      <ToastAlert show={!!alertMessage} onClose={setAlertMessage} text={alertMessage} timeout={2500} />
    </section>
  )
}