import {useEffect, useState, useContext} from "react";
import {useNavigate} from "react-router";
import {AuthContext} from "../context/AuthContext";
import PointImg from "../assets/images/img_point.png";
import {ReactComponent as ArrowRight} from "../assets/icons/arrow_right.svg";

export default function BuzzvilAd({adParams, extraStyle}) {
  const navigate = useNavigate();
  const {API_SERVER_DOMAIN, useAccessToken} = useContext(AuthContext);
  const accessToken = useAccessToken();
  const [nativeAd, setNativeAd] = useState({});

  useEffect(() => {
    (async (adParams) => {
      const data = await fetch(`${API_SERVER_DOMAIN}/yaongcon/ad/buzzvil/ads?platform=${adParams.platform}&unit_id=${adParams.unitId}&ifa=${adParams.ifa}&ifv=${adParams.ifv}`, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${accessToken}`
        }
      });
      const json =  await data.json();
      setNativeAd(json);
    })(adParams);
  }, [adParams]);

  return (
    nativeAd.result === "ok" && nativeAd.infos.map((item) => (
      <div key={item.id} className={`buzzvilad-container ${extraStyle}`}>
        {item.creative.click_url && (
          <a href={item.creative.click_url} target="_blank">
            <img
              className="buzzvilad-image"
              src={item.creative.image_url}
              onLoad={async () => await fetch(item.impression_urls)}
              alt={item.creative.title} 
            />
          </a>
        )}
        {!item.creative.click_url && (
          <img
            className="buzzvilad-image"
            src={item.creative.image_url}
            onLoad={async () => await fetch(item.impression_urls)}
            alt={item.creative.title} 
          />
        )}
        <div className="buzzvilad-content">
          <div className="buzzvilad-icon-title">
            <img className="buzzvilad-icon" src={item.creative.icon_url} alt="로고" />
            <p className="buzzvilad-title">{item.creative.title}</p>
          </div>
          <p className="buzzvilad-desc">{item.creative.description}</p>
          {item.reward_condition === "action" && (
            <div 
              className="buzzvilad-footer-wrapper"
              onClick={() => {
                navigate("/offerwall/action-ad-detail", 
                  {state: {
                    id: item.id,
                    unitId: adParams.unitId,
                    ifa: adParams.ifa,
                    ifv: adParams.ifv,
                    payload: item.payload,
                    image: item.creative.image_url,
                    icon: item.creative.icon_url,
                    title: item.creative.title,
                    reward: item.reward,
                    checkParticipatioUrl: item.check_participation_url
                  }
                });
              }}
            >
              <div className="buzzvilad-footer">
                <img className="point-img" src={PointImg} alt="points" />
                <span className="point-number">+{item.reward}</span>
                <span>{item.creative.call_to_action}</span>
              </div>
            </div>
          )}
          <p 
            className="buzzvilad-goto"
            onClick={() => window.flutter_inappwebview.callHandler("goto_offerwall_main")}
          >
            포인트 더 받으러 가기! &nbsp;&gt;
          </p>
        </div>
      </div>
    ))
  );
}