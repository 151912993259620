import {ReactComponent as CloseIcon} from "../../assets/icons/close.svg";


export function BottomPopup(props) {

  const {title = "", open, setOpen, className = "", ...otherProps} = props;

  return (
    <div className={open ? `popup-show ${className}` : `popup-hide ${className}`} {...otherProps}>
      <div className="popup-container">
        <div className="popup-header">
          <h3>{title}</h3>
          <CloseIcon onClick={() => setOpen(false)}/>
        </div>
        <div className="popup-content">
          {props.children}
        </div>
      </div>
    </div>
  )
}