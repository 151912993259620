import {usePlatform} from "../utils/hooks";

export default function GotoAppStore() {
  const platform = usePlatform();
  const urlParams = new URLSearchParams(window.location.search);
  const refCode = urlParams.get("refCode");
  
  switch (platform) {
    case "android":
      window.location.replace(`https://play.google.com/store/apps/details?id=com.yaong.app&referrer=${refCode}`);
      return;
    case "ios":
      window.location.replace("https://apps.apple.com/kr/app/%EC%95%BC%EC%98%B9%EC%83%81%ED%9A%8C-%EC%89%BD%EA%B3%A0-%EC%A6%90%EA%B1%B0%EC%9A%B4-%ED%8F%AC%EC%9D%B8%ED%8A%B8-%EB%A6%AC%EC%9B%8C%EB%93%9C%EC%95%B1-%EC%95%B1%ED%85%8C%ED%81%AC/id6502283766");
      return;
    default:
      window.location.replace("/login");
      return;
  }
}