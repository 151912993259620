
import { useEffect } from "react";

export async function getPaidToWatchAds(RTE, url, token, type = undefined) {
  if (RTE === "APP") {
    // 광고실행 전, 보상 획득용 인증키 획득
    const response = await fetch(url, {
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json",
      }
    });
    const jsonData = await response.json();
    if (jsonData.result === "ok") {
      // alert("Ad Key 수신 완료, 광고실행 요청 시작");
      // 광고실행
      window.flutter_inappwebview.callHandler("run_admob", type);
      return { adKey: jsonData.adKey ?? jsonData.code, message: "" };
    } else {
      console.error(jsonData);
      return { adKey: false, message: jsonData.message };
    }
  } else {
    return { adKey: false, message: "광고 시청은 앱에서만 가능해요" };
  }
}

export function useAdsListener(url, token, params, callback) {

  const listener = async ({ detail }) => {
    // alert("광고 시청 종료: " + detail.event + ", " + JSON.stringify(detail.reward));
    
    if (detail.event === "error") {
      const {code, message} = detail.reward;
      alert(code + ": " + message);
      return;
    }

    /* if (parseInt(detail.reward) <= 0) {
      alert("광고 시청 도중 종료함");
      return;
    } */

    if (params.adKey && (detail.event === "end" || detail.event === "charge")) {
      // 걸음 수
      if (detail.event === "charge") {
        params.walk = detail.reward;
      }

      // alert("보상 지급 요청 시작");
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params)
      });
      const jsonData = await response.json();
      // alert("보상 지급 결과, " + jsonData.result);
      if (jsonData.result !== "ok") {
        alert(jsonData.message);
        console.error(jsonData);
      }
      // 걸음 수 전환 보상 지급 성공시 플러터로 알림
      if (jsonData.result === "ok" && detail.event === "charge") {
        await window.flutter_inappwebview.callHandler("run_admob", "charge-end");
      }
      callback();
    } else if (!params.adKey && detail.event === "end") {
      callback();
    }
  }

  useEffect(() => {
    window.addEventListener("ads", listener);

    return () => {
      window.removeEventListener("ads", listener);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, params]);
}