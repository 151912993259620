import {ReactComponent as ArrowIcon} from "../../assets/icons/arrow_left.svg";
import {useNavigate} from "react-router";
import Toggle from "../../components/common/Toggle";
import {ReactComponent as ArrowRight} from "../../assets/icons/arrow_right.svg";
import KakaoIcon from "../../assets/icons/kakao-login.png";
import NaverIcon from "../../assets/icons/naver-login.png";
import GoogleIcon from "../../assets/icons/google-login.png";
import AppleIcon from "../../assets/icons/apple-login.png";
import {useContext, useState} from "react";
import {AuthContext} from "../../context/AuthContext";
import {AlertDialog} from "../../components/common/Modal";
import {useLocation} from "react-router-dom";


export default function SettingsPage() {

  const {API_SERVER_DOMAIN, useAccessToken, signOut} = useContext(AuthContext);
  const {state} = useLocation();
  const navigate = useNavigate();
  const [logoutOpen, setLogoutOpen] = useState(false);
  const accessToken = useAccessToken();

  // 푸시 알림 설정
  const pushSettings = [
    // {key: "yaong", name: "야옹이를 부탁해", description: "야옹이를 부탁해 푸시 받기", onCheck: (e) => handleCheck("yaong", e.target.checked)},
    {key: "tori", name: "토리를 구해줘", description: "토리를 구해줘 시작 시간 및 종료 시간 푸시 받기", onCheck: (e) => handleCheck("tori", e.target.checked)},
    {key: "summonBuddy", name: "친구소환", description: "소환한 친구가 가입했을 때 푸시 받기", onCheck: (e) => handleCheck("summonBuddy", e.target.checked)},
    {key: "pool", name: "수영장", description: "수영장 쿠폰 및 확정, 양도 등 정보 푸시 받기", onCheck: (e) => handleCheck("pool", e.target.checked)}
  ];

  const handleCheck = async (key, value) => {
    state.push[key] = value;
    
    const response = await fetch(`${API_SERVER_DOMAIN}/user/profile/push/setting`, {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(state.push)
    });
    const jsonData = await response.json();
    if (jsonData.result !== "ok") {
      console.error(jsonData.message);
      alert(jsonData.message);
    }
  }

  const ServiceIcon = (() => {
    switch (state.service) {
      case "kakao":
        return KakaoIcon;
      case "naver":
        return NaverIcon;
      case "google":
        return GoogleIcon;
      case "apple":
        return AppleIcon;
    }
  })();

  return (
    <section className="settings-page">
      <div className="page-header header-shadow">
        <ArrowIcon onClick={() => navigate(-1)} style={{left: 24}}/>
        <h3>알림/로그인 설정</h3>
      </div>

      <article className="push-settings">
        {pushSettings.map((item) => (
          <div key={item.name} className="setting-box">
            <div>
              <p>{item.name}</p>
              <p className="text-light">{item.description}</p>
            </div>
            <Toggle onChange={item.onCheck} defaultChecked={state.push[item.key]}/>
          </div>
        ))}
      </article>

      <div className="setting-box">
        <p>내 로그인 상태</p>
        <p className="text-light">연락처가 바뀌더라도 혜택을 이어가세요.</p>
        <div style={{margin: "12px 0", display: 'flex', gap: 8, fontSize: 15}}>
          <span style={{fontWeight: "bold"}}>
            {state.service === "kakao" && "카카오"}
            {state.service === "naver" && "네이버"}
            {state.service === "google" && "구글"}
            {state.service === "apple" && "애플"}
          </span>
          <span className="text-light">{state.email}</span>
        </div>

        <div className="login-icons">
          <div>
            <img src={ServiceIcon} alt="kakao-icon" height={19}/>
          </div>
        </div>
      </div>

      <div className="setting-box">
        <div style={{display: 'flex'}}>
          <p style={{marginRight: 'auto'}}>로그아웃</p>
          <ArrowRight width={14} onClick={() => setLogoutOpen(true)}/>
        </div>
        <p className="text-light">로그아웃 시 캐시가 적립되지 않습니다.</p>
      </div>

      <div className="setting-box">
        <div style={{display: 'flex'}}>
          <p style={{marginRight: 'auto'}}>회원탈퇴</p>
          <ArrowRight width={14} onClick={() => navigate("/user/delete", {state: {nickname: state.nickname}})}/>
        </div>
        <p className="text-light">{"회원탈퇴 시 보유한 모든 캐시, 포인트가 삭제되며 7일간 재가입이 불가능합니다."}</p>
      </div>

      <div className="setting-box" style={{marginTop: 8}}>
        <div style={{display: 'flex'}}>
          <p style={{marginRight: 'auto'}}>대표 이메일</p>
        </div>
        <p className="text-light">help@yaongstore.com</p>
      </div>

      <AlertDialog open={logoutOpen} title={"로그아웃"}
                   onClose={() => setLogoutOpen(false)}
                   onCancel={() => setLogoutOpen(false)}
                   buttonText={"로그아웃"}
                   buttonSecondText={"취소"}
                   onOk={signOut}
                   text={"로그아웃 시 포인트 적립이 중지됩니다.\n그래도 로그아웃하시겠어요?"}/>

    </section>
  )
}