import "../../assets/styles/offerwall.scss";
import {useContext, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router";
import {AuthContext} from "../../context/AuthContext";
import Button from "../../components/common/Button";
import {ReactComponent as ArrowIcon} from "../../assets/icons/arrow_left.svg";

export default function ActionAdDetail() {
  const {state} = useLocation();
  const navigate = useNavigate();
  const {API_SERVER_DOMAIN, useAccessToken} = useContext(AuthContext);
  const accessToken = useAccessToken();
  const [data, setData] = useState({});

  useEffect(() => {
    (async () => {
      const response = await fetch(`${API_SERVER_DOMAIN}/yaongcon/ad/buzzvil/participate`, {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${accessToken}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          "unit_id": state.unitId,
          "campaign_id": state.id,
          "ifa": state.ifa,
          "payload": state.payload,
          "ifv": state.ifv,
          "device_name": ""
        })
      });
      const data = await response.json();
      setData(data);
    })();
  }, []);

  return (
    <section className="action-ad-detail">
      <nav>
        <ArrowIcon onClick={() => navigate(-1)}/>
      </nav>
      <img src={state.image} alt={state.title} />
      <div className="ad-detail-wrapper">
        <div className="ad-detail-header">
          <img className="ad-icon" src={state.icon} alt="로고" />
          <p className="ad-title">
            <div>{state.title}</div>
            <div>{data.call_to_action}</div>
          </p>
          <span className="ad-reward">+{state.reward}P</span>
        </div>
        <div className="ad-detail-content">
          {data.action_description && 
            data.action_description.split(/\[(.*?)\]/g).map((str) => {
              if (str === "참여방법" || str === "주의사항") {
                return <div className="ad-title">{str}</div>;
              } else if (str) {
                return <div className="ad-content-item">{str}</div>;
              }
            })
          }
        </div>
        <div className="ad-detail-footer">
          <a href={data.landing_url} target="_blank">
            <Button onClick={() => navigate(-1)}>참여하기</Button>
          </a>
        </div>
      </div>
    </section>
  );
}