import {ReactComponent as ArrowIcon} from "../../assets/icons/arrow_left.svg";
import {useNavigate} from "react-router";
import {ReactComponent as ArrowRight} from "../../assets/icons/arrow_right.svg";


export default function PolicyPage() {

  const navigate = useNavigate();

  return (
    <section className="policy-page">
      <div className="page-header header-shadow">
        <ArrowIcon onClick={() => navigate(-1)} style={{left: 24}}/>
        <h3>약관 및 개인정보 처리 방침</h3>
      </div>

      <article className="policy-list">
        <a href="https://cream-cushion-517.notion.site/af425ee8e2e144a986305efa80772377?pvs=4" target="_blank">
          <p>서비스 이용 약관</p>
          <ArrowRight width={13} />
          {/* <ArrowRight width={13} onClick={() => navigate("/user/policy/terms-of-use")}/> */}
        </a>
        <a href="https://plip.kr/pcc/e72d3829-a44c-486a-963d-f027390015b1/consent/3.html" target="_blank">
          <p>개인정보 수집 및 이용</p>
          <ArrowRight width={13} />
          {/* <ArrowRight width={13} onClick={() => navigate("/user/policy/collection-and-use")}/> */}
        </a>
        <a href="https://plip.kr/pcc/e72d3829-a44c-486a-963d-f027390015b1/privacy/2.html" target="_blank">
          <p>개인정보 처리방침</p>
          <ArrowRight width={13} />
          {/* <ArrowRight width={13} onClick={() => navigate("/user/policy/processing")}/> */}
        </a>
      </article>
    </section>
  )
}

export function AgreementDetail(props) {

  const navigate = useNavigate();
  const {type} = props; // terms of service, personal information collection, processing of personal information

  const getPageTitle = () => {
    switch (type) {
      case "service": return "서비스 이용 약관";
      case "collection": return "개인정보 수집 및 이용";
      case "processing": return "개인정보 처리방침";
      default: return "서비스 이용 약관";
    }
  }

  const getAgreementContent = () => {
    switch (type) {
      case "service": return <TermsOfService/>;
      case "collection": return <PersonalInfoCollectionAndUse/>;
      case "processing": return <ProcessingOfPersonalInfo/>;
      default: return <TermsOfService/>;
    }
  }

  return (
    <section>
      <div className="page-header header-shadow">
        <ArrowIcon onClick={() => navigate(-1)} style={{left: 24}}/>
        <h3>{getPageTitle()}</h3>
      </div>
      <article className="agreement-detail">
        <div>
          {getAgreementContent()}
        </div>
      </article>
    </section>
  )
}

function TermsOfService() {
  return <>
    <h4>서비스 이용 약관</h4>
    <p>내용 내용 내용 내용 내용 내용 내용 내용 내용 내용 내용 내용</p>
    <p>내용 내용 내용 내용 내용 내용 내용 내용 내용 내용 내용 내용</p>
    <p>내용 내용 내용 내용 내용 내용 내용 내용 내용 내용 내용 내용</p>
    <p>내용 내용 내용 내용 내용 내용 내용 내용 내용 내용 내용 내용</p>
    <p>내용 내용 내용 내용 내용 내용 내용 내용 내용 내용 내용 내용</p>
  </>
}

function PersonalInfoCollectionAndUse() {
  return <>
    <h4>개인정보 수집 및 이용</h4>
    <p>내용 내용 내용 내용 내용 내용 내용 내용 내용 내용 내용 내용</p>
    <p>내용 내용 내용 내용 내용 내용 내용 내용 내용 내용 내용 내용</p>
    <p>내용 내용 내용 내용 내용 내용 내용 내용 내용 내용 내용 내용</p>
    <p>내용 내용 내용 내용 내용 내용 내용 내용 내용 내용 내용 내용</p>
    <p>내용 내용 내용 내용 내용 내용 내용 내용 내용 내용 내용 내용</p>
  </>
}

function ProcessingOfPersonalInfo() {
  return <>
    <h4>개인정보 처리방침</h4>
    <p>내용 내용 내용 내용 내용 내용 내용 내용 내용 내용 내용 내용</p>
    <p>내용 내용 내용 내용 내용 내용 내용 내용 내용 내용 내용 내용</p>
    <p>내용 내용 내용 내용 내용 내용 내용 내용 내용 내용 내용 내용</p>
    <p>내용 내용 내용 내용 내용 내용 내용 내용 내용 내용 내용 내용</p>
    <p>내용 내용 내용 내용 내용 내용 내용 내용 내용 내용 내용 내용</p>
  </>
}