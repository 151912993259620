import {ReactComponent as ArrowIcon} from "../../assets/icons/arrow_left.svg";
import MeowImg from "../../assets/images/Meaw.png";
import ErrorCat from "../../assets/images/crying_cat.png";
import React, {useState, useContext} from "react";
import {useNavigate} from "react-router";
import {useLocation} from "react-router-dom";
import {TextField} from "../../components/common/Input";
import Button from "../../components/common/Button";
import ModalDialog from "../../components/common/Modal";
import {AuthContext} from "../../context/AuthContext";

export default function SummonerAddPage() {

  const navigate = useNavigate();
  const {state} = useLocation();
  const [userCode, setUserCode] = useState("");
  const [errorOpen, setErrorOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const {API_SERVER_DOMAIN, useAccessToken} = useContext(AuthContext);
  const accessToken = useAccessToken();

  async function submitCode() {
    if (state.refCode === userCode.toUpperCase()) {
      setErrorMessage("스스로 초대 할 수 없어요.");
      setErrorOpen(true);
      return;
    }

    const response = await fetch(`${API_SERVER_DOMAIN}/user/ref/submit`, {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ref_code: userCode
      })
    });
    const jsonData = await response.json();

    if (jsonData.result === "ok") {
      setSuccessOpen(true);
    } else {
      if (jsonData.message.startsWith("존재")) {
        setErrorMessage("존재 하지 않는 코드 예요.\n다시 확인 해 주세요!");
      } else {
        setErrorMessage(jsonData.message);
      }
      setErrorOpen(true);
    }
  }

  return (
    <section className="summoner-code-page">
      <div className="page-header header-shadow">
        <ArrowIcon onClick={() => navigate("/user")} style={{left: 24}}/>
        <h3>친구 소환 코드</h3>
      </div>

      <article>
        <h1>친구 소환 코드를 입력해 주세요.  <img src={MeowImg} alt="meow-icon" width={28}/></h1>

        <label htmlFor={"friend-code"}>코드 입력</label>
        <TextField type="text" name={"friend-code"} onChange={setUserCode} placeholder={"코드를 입력해 혜택을 받아보세요."}/>

        <div className="code-guidelines">
          <ul>
            <li>계정당 한 번만 적용되요.</li>
            <li>받은 내역은 My &#62; 포인트 &#62; 획득 포인트로 이동해<br/>확인해 보세요.</li>
          </ul>
        </div>

        <Button onClick={submitCode}>확인</Button>
      </article>

      <ModalDialog open={successOpen} onClose={() => setSuccessOpen(false)}
                   title={"코드 입력 완료"} className="alert-modal">
        <p>
          코드가 정상적으로 입력 되었어요.
        </p>
        <Button onClick={() => setSuccessOpen(false)}>확인</Button>
      </ModalDialog>

      <ModalDialog open={errorOpen && errorMessage} onClose={() => setErrorOpen(false)}
                   title={<>잠시만요 <img src={ErrorCat} alt="error-icon" width={40}/></>}
                   className="alert-modal">
        <p>
          {errorMessage}
        </p>
        <Button onClick={() => setErrorOpen(false)}>확인</Button>
      </ModalDialog>

    </section>
  )
}