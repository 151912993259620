import {AccordionBox} from "../../components/common/Accordion";
import React, {useEffect, useContext} from "react";
import {ReactComponent as CloseIcon} from "../../assets/icons/close.svg";
import {useNavigate} from "react-router";
import Button from "../../components/common/Button";
import LuckySpinWheel from "../../assets/images/YaongLuckySpinWheel.png";
import LuckySpinFrame from "../../assets/images/lucky-spin-frame.png";
import LuckySpinBtn from "../../assets/images/spin-btn.png";
import LuckySpinBtnDisabled from "../../assets/images/spin-btn-disabled.png";
import {useState} from "react";
import PointImg from "../../assets/images/point-large.png";
import ModalDialog from "../../components/common/Modal";
import VideoIcon from "../../assets/images/img_ad.png";
import RewardVideo from "../../assets/images/Confetti_transparent.webm";
import ToastAlert from "../../components/common/Toast";
import useSWR from "swr";
import {AuthContext} from "../../context/AuthContext";
import {useScript, useRunTimeEnvironment} from "../../utils/hooks";
import {getPaidToWatchAds, useAdsListener} from "../../services/admob";
import summonFriends from "../../services/summoner";
import BuzzvilAd from "../../components/BuzzvilAd";


export default function CatLuckySpin() {

  const navigate = useNavigate();
  const [wheelStyle, setWheelStyle] = useState("");
  const [pointSuccessOpen, setPointSuccessOpen] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);
  const [adKey, setAdKey] = useState();
  const [adParams, setAdParams] = useState({});

  const {API_SERVER_DOMAIN, useAccessToken, fetchWithToken, appInfo} = useContext(AuthContext);
  const accessToken = useAccessToken();
  const RTE = useRunTimeEnvironment();

  const {data: info, mutate: revalidateInfo} = useSWR(accessToken && [`${API_SERVER_DOMAIN}/user/luckyspin/info`, accessToken], fetchWithToken);
  const {data: draw, mutate: revalidateDraw} = useSWR(
    accessToken && [`${API_SERVER_DOMAIN}/user/luckyspin/draw`, accessToken],
    fetchWithToken, 
    {
      revalidateIfStale: false,
      revalidateOnFocus: true,
      revalidateOnReconnect: true
    }
  );

  /* Prize Map
  1. 포인트 5: id 25
  2. 부스터 10: id 51
  3. 포인트 15: id 24
  4. 은신술: id 54
  5. 포인트 100: id 21
  6. 포인트 300: id 23 */
  async function onSpin() {
    if (info.spin_count === 3 && info.ticket_count === 0) {
      setAlertMessage("오늘은 모두 참여 했어요. 내일 다시 참여해 주세요.");
      return;
    }
    if (info.ticket_count < 1) {
      return;
    }

    const prizeMap = {
      25: 1, 51: 2, 24: 3, 54: 4, 21: 5, 23: 6
    };

    setWheelStyle(`spinning-${prizeMap[draw.info.itemId]}`);
    setTimeout(() => {
      setPointSuccessOpen(true);
      setShowConfetti(true);
      setWheelStyle("");
    }, 4300);

    // 상품 지급 처리
    await fetch(`${API_SERVER_DOMAIN}/user/luckyspin/give`, {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${accessToken}`,
        "Content-Type": "application/json"
      }
    });
    revalidateInfo();
  }

  async function startAds() {
    const response = await getPaidToWatchAds(RTE, `${API_SERVER_DOMAIN}/user/ad/luckyspin`, accessToken);
    if (response.adKey) {
      setAdKey(response.adKey);
    } else {
      setAlertMessage(response.message);
    }
  }

  function getTicket(req) {
    if (info.ticket_count > 0) {
      setAlertMessage("먼저 럭키스핀을 돌려 주세요");
      return;
    }

    if (req === 1) {
      startAds();
    } else {
      summonFriends(2, "", accessToken);
    }
  }

  useAdsListener(
    `${API_SERVER_DOMAIN}/user/ad/luckyspin`,
    accessToken,
    {adKey: adKey},
    async () => {
      await revalidateInfo();
      await revalidateDraw();
    }
  );

  useEffect(() => {
    let pageContainer = document.getElementsByClassName("page-container")[0];
    pageContainer?.scrollTo(0, 0);

    const getNativeInfo = async () => {
      const info = await window.flutter_inappwebview.callHandler("get_native_lucky");
      setAdParams({
        platform: appInfo.os.substring(0, 1).toUpperCase(),
        unitId: info.unit_id,
        ifa: info.ifa,
        ifv: info.ifv
      });
    }
    if (RTE === "APP") {
      getNativeInfo();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useScript("https://t1.kakaocdn.net/kakao_js_sdk/2.7.1/kakao.min.js");

  if (!info || !draw) return;

  return (
    <section>
      <div className="page-header header-shadow">
        <h1>야옹이의 럭키 스핀</h1>
        <CloseIcon onClick={() => navigate("/playground")}/>
      </div>

      <article className="yaong-lucky-spin-page">
        <div className="yaong-spin-wheel">
          <img src={LuckySpinWheel} alt="lucky-spin-wheel" width={257} className={wheelStyle}/>
          <img src={LuckySpinFrame} alt="wheel-frame" width={345}/>
          <img src={info.ticket_count < 1 ? LuckySpinBtnDisabled : LuckySpinBtn} alt="spin-button" width={80} onClick={onSpin}/>
        </div>
        <div>
          <h2>야옹이의 꼬리는 어디로?</h2>
          <p>하루 세 번 야옹꼬리의 스핀행운을 얻으세요~</p>

          <Button 
            disabled={info.spin_count >= 3}
            onClick={() => {
              getTicket(1);
            }}
          >
            <img src={VideoIcon} alt="video-icon" height={25}/>
            광고 보고 럭키스핀 돌리기
          </Button>
          <Button 
            disabled={info.spin_count >= 3}
            onClick={() => {
              getTicket(2);
            }}
          >
            친구초대 럭키스핀 돌리기
          </Button>

          <div className="today-spins info-bubble">
            <span>오늘 참여 횟수</span>
            <span className="text-grey">(<strong>{info.spin_count}</strong>/3)</span>
          </div>
        </div>
        {adParams.unitId && 
          <BuzzvilAd adParams={adParams} extraStyle="buzzilad-container-playground" />
        }

        {showConfetti &&
          <div id="bg-reward">
            <video autoPlay muted loop>
              <source src={RewardVideo} type="video/mp4"/>
            </video>
          </div>
        }
      </article>

      <div className="pool-booking-terms" style={{paddingBottom: 0}}>
        <AccordionBox header="이용 안내" defaultOpen={true}>
        <ul>
          <li>부적절한 방법으로 포인트를 적립한 회원은 포인트 적립이 취소돼요</li>
          <li>해당 이벤트는 당사 사정에 의해 사전 공지 없이 내용이 변경되거나 기간이 조정될 수 있어요.</li>
        </ul>
        </AccordionBox>
      </div>


      <ModalDialog
        className={"point-success-modal yaong-spin-modal"}
        open = {pointSuccessOpen}
        title={`${draw.info?.itemName ?? ""}를 받았어요`}
        image={<img src={PointImg} alt="points" height={100}/>}
        onCancel = {() => setPointSuccessOpen(false)}
      >
        <p>축하해야옹!!</p>
        <p>{draw.info?.itemName ?? ""}를 획득하셨다옹~</p>
        <div>
          <span className="text-grey">하루 최대 3번 참여할 수 있어요</span>
        </div>
        <Button onClick={() => {
          setShowConfetti(false);
          setPointSuccessOpen(false);
        }}>확인</Button>
        <Button onClick={() => {
          setShowConfetti(false);
          setPointSuccessOpen(false);
          startAds();
        }}>한번 더 돌리기</Button>
      </ModalDialog>

      <ToastAlert show={!!alertMessage} onClose={setAlertMessage} text={alertMessage} timeout={2500} />
    </section>
  )
}