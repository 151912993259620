import React, {useEffect, useState, useContext, useMemo, useCallback} from 'react';
import SuccessImg from "../../assets/images/success.png";
import VideoIcon from "../../assets/images/img_ad.png";
import {ReactComponent as CloseIcon} from "../../assets/icons/close.svg";
import {ReactComponent as SpinPointer} from "../../assets/images/spin-pointer.svg";
import SpinImg from "../../assets/images/img_lucky.png";
import CouponImg from "../../assets/images/img_coupon.png";
import PointImg from "../../assets/images/img_point.png";
import {ReactComponent as SpinButton1} from "../../assets/images/spin-button.svg";
import {ReactComponent as SpinButton2} from "../../assets/images/spin-button2.svg";
import {ReactComponent as SpinButton3} from "../../assets/images/spin-button3.svg";
import {ReactComponent as SpinButton4} from "../../assets/images/spin-button4.svg";
import {ReactComponent as SpinButton5} from "../../assets/images/spin-button5.svg";
import {useNavigate, useLocation} from "react-router";
import "../../assets/styles/lucky.scss";
import Button from "../../components/common/Button";
import {ReactComponent as AppleStoreLogo} from "../../assets/images/apple_store.svg";
import {ReactComponent as GooglePlayLogo} from "../../assets/images/google_play.svg";
import {useRunTimeEnvironment} from "../../utils/hooks";
import {AccordionBox} from "../../components/common/Accordion";
import ModalDialog, {AlertDialog} from "../../components/common/Modal";
import useSWR from "swr";
import {AuthContext} from "../../context/AuthContext";
import {useScript} from "../../utils/hooks";
import summonFriends from "../../services/summoner";
import {isBefore, isAfter, isWithinInterval, getDay, format} from "date-fns";
import {getPaidToWatchAds, useAdsListener} from "../../services/admob";
import ToastAlert from "../../components/common/Toast";
// import Wheel from "../../assets/images/LuckySpinWheel.png";
/* 
 (예약 완료 후, 앱전용)
 Wheel1,2 ~ 24.06.30 
*/
import Wheel_1 from "../../assets/images/LuckySpinWheel-1.png";
import Wheel_2 from "../../assets/images/LuckySpinWheel-2.png";
/*
(예약 완료 후, 앱전용)
 Wheel3,4 24.07.01 ~ 07.12
*/
import Wheel_3 from "../../assets/images/LuckySpinWheel-3.png";
import Wheel_4 from "../../assets/images/LuckySpinWheel-4.png";
/*
(예약 완료 후, 앱전용)
 Wheel5,6 24.07.13 ~
*/
import Wheel_5 from "../../assets/images/LuckySpinWheel-5.png";
import Wheel_6 from "../../assets/images/LuckySpinWheel-6.png";

export default function LuckySpinPage(props) {

  const { isAfterBooking } = props;
  const navigate = useNavigate();
  const RTE = useRunTimeEnvironment();

  const [showPrize, setShowPrize] = useState(false);
  const [isShot, setIsShot] = useState(false);
  const [confirmCancellation, setConfirmCancellation] = useState(false);
  const [adKey, setAdKey] = useState();
  const [adPath, setAdPath] = useState();
  const [toastMessage, setToastMessage] = useState(false);

  const { state } = useLocation();
  const { API_SERVER_DOMAIN, useAccessToken, fetchWithToken } = useContext(AuthContext);
  const accessToken = useAccessToken();

  // 예약 취소 화면에서 럭키 스핀 사용 불가
  const cancellation = state?.mode === "delete";
  // 친구 소환 횟수 제한
  const MAMIMUM_INVITATION_LIMIT = 1;

  // 럭키 스핀 화면 직접 진입인 경우 APP에서만 사용 가능
  // 예약 완료 화면일 경우 누적 예약 횟수 검사
  const isAvailable = !isAfterBooking ? RTE === "APP" : (state?.accBookingCount !== "undefined" && state?.accBookingCount < 6);

  const weekdays = ["월", "화", "수", "목", "금", "토", "일"];

  // 사용권
  const { data: ticket, mutate: mutateTicket } = useSWR(accessToken && [`${API_SERVER_DOMAIN}/pool/luckyspin/info`, accessToken], fetchWithToken);  

  // 상품 로드
  const { data: reward, mutate: reloadReward } = useSWR(!cancellation && isAvailable && accessToken && [`${API_SERVER_DOMAIN}/pool/luckyspin/draw?type=${isAfterBooking ? 1 : 2}`, accessToken], fetchWithToken, {
    revalidateIfStale: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: false
  });

  // 스핀 시작하면 지급처리
  /* const fetchWithTokenPost = ([url, token]) => fetch(url, {
    method: 'POST',
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    }
  }).then(res => res.json());
  const { data: give } = useSWRImmutable(!cancellation && isAvailable && isShot && accessToken && [`${API_SERVER_DOMAIN}/pool/luckyspin/give`, accessToken], fetchWithTokenPost); */
  

  // 광고 시청, 친구 소환 횟수
  const { data: left, mutate: mutateLeft } = useSWR(!isAfterBooking && accessToken && [`${API_SERVER_DOMAIN}/pool/luckyspin/invite`, accessToken], fetchWithToken);

  const [rewardTitle, setRewardTitle] = useState();
  const [rewardImage, setRewardImage] = useState();
  const [rewardName, setRewardName] = useState();
  const [wheelStyle, setWheelStyle] = useState("");

  useScript("https://t1.kakaocdn.net/kakao_js_sdk/2.7.1/kakao.min.js");

  useEffect(() => {
    if (reward?.result === "ok") {
      switch (reward?.info?.itemId.toString().length) {
        case 1:
          setRewardTitle("축하합니다!! 수영장 할인 쿠폰을 획득하셨습니다.");
          setRewardImage(CouponImg);
          setRewardName("수영장 할인 쿠폰");
          break;
        case 2:
          setRewardTitle(`축하합니다!! ${reward.info.itemName}을 획득하셨습니다.`);
          setRewardImage(PointImg);
          setRewardName(reward.info.itemName);
          break;
        case 3:
          setRewardTitle("축하합니다!! 럭키스핀 무료 1회 상품을 획득하셨습니다.");
          setRewardImage(SpinImg);
          setRewardName("럭키스핀 무료 1회");
          break;
        default:
          setRewardTitle("");
          setRewardImage();
          setRewardName("");
      }
    }
  }, [reward?.result, reward?.info]);

  useEffect(() => {
    // 예약 완료 화면에 URL 직접 접근
    if (isAfterBooking && state === null) {
      window.location.replace("/pool");
    }

    document.getElementsByClassName("page-container")[0]?.scrollTo(0, 0);
  }, []);

  const onSpin = useCallback(async () => {
    if (!reward || cancellation || !isAvailable) {
      setToastMessage("럭키스핀을 사용할 수 없습니다.");
      return;
    }

    if (reward.result !== "ok") {
      setToastMessage(reward.message);
      return;
    }

    setIsShot(true);

    await fetch(`${API_SERVER_DOMAIN}/pool/luckyspin/give`, {
      method: 'POST',
      headers: {
        "Authorization": `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      }
    });

    const spinMap = [
      {1: 1, 15: 2, 3: 3, 13: 4, 101: 5, 12: 6},
      {1: 1, 16: 2, 3: 3, 14: 4, 101: 5, 11: 6},
      {2: 1, 15: 2, 3: 3, 13: 4, 101: 5, 12: 6},
      {2: 1, 16: 2, 3: 3, 14: 4, 101: 5, 11: 6},
      {19: 1, 15: 2, 17: 3, 13: 4, 101: 5, 12: 6},
      {20: 1, 16: 2, 18: 3, 14: 4, 101: 5, 11: 6},
    ];

    // className "spinning-[n]" => stops at [n]th prize on the wheel to the left
    setWheelStyle(`spinning-${spinMap[wheel.id-1][reward.info.itemId]}`);
    setTimeout(() => {
      setShowPrize(true);
      setWheelStyle("");
      if (!isAfterBooking) setIsShot(false);
    }, 4300);
  }, [reward?.result, reward?.info?.itemId]);

  function requestCancelReservation() {
    fetch(`${API_SERVER_DOMAIN}/pool/book`, {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        book_no: state.bookNo,
        reason: "",
        name: "",
        phone: "",
        bank: "",
        account: ""
      })
    })
    .then(response => response.json())
    .then(jsonData => {
      if (jsonData.result === "ok") {
        alert("예약이 취소 되었습니다.");
        navigate("/pool/ticket");
      } else {
        console.error(jsonData);
      }
    });
  }

  const wheel = useMemo(() => {
    if (isBefore(new Date(), new Date(2024, 6, 1)) && isAfterBooking) {
      return {"id": 1, "img": Wheel_1};
    }
    if (isBefore(new Date(), new Date(2024, 6, 1)) && !isAfterBooking) {
      return {"id": 2, "img": Wheel_2};
    }
    if (isWithinInterval(new Date(), {start: new Date(2024, 6, 1), end: new Date(2024, 6, 12, 23, 59, 59)}) && isAfterBooking) {
      return {"id": 3, "img": Wheel_3};
    }
    if (isWithinInterval(new Date(), {start: new Date(2024, 6, 1), end: new Date(2024, 6, 12, 23, 59, 59)}) && !isAfterBooking) {
      return {"id": 4, "img": Wheel_4};
    }
    if (isAfter(new Date(), new Date(2024, 6, 12)) && isAfterBooking) {
      return {"id": 5, "img": Wheel_5};
    }
    if (isAfter(new Date(), new Date(2024, 6, 12)) && !isAfterBooking) {
      return {"id": 6, "img": Wheel_6};
    }
  }, []);

  const grantPrize = () => {
    setShowPrize(false);
    reloadReward();
    mutateTicket();
  }

  useScript(`${process.env.PUBLIC_URL}/flutter/flutter_script.js`);

  const startAds = async path => {
    const response = await getPaidToWatchAds(RTE, `${API_SERVER_DOMAIN}${path}`, accessToken);
    if (response.adKey) {
      setAdKey(response.adKey);
      setAdPath(path);
    } else {
      setToastMessage(response.message);
    }
  }

  // 광고 시청 보상 지급
  const adsCallback = () => {
    mutateLeft();
    mutateTicket();
    reloadReward();
    setIsShot(false);
  }
  useAdsListener(`${API_SERVER_DOMAIN}${adPath}`, accessToken, {adKey: adKey}, adsCallback);

  if ((!isAfterBooking && !left) || !ticket) return;

  return (
    <React.Fragment>
      <div className="page-header header-shadow">
        <h1>{isAfterBooking ? "예약 신청 완료" : "럭키 스핀"}</h1>
        <CloseIcon onClick={() => navigate("/pool")}/>
      </div>
      {isAfterBooking &&
        <section className="booking-success-page">
          <img src={SuccessImg} alt="booking-success" height={60}/>
          {state?.reservedDate &&
            <h3>{format(new Date(state.reservedDate), "yyyy.MM.dd")}({weekdays[getDay(new Date(state.reservedDate))-1]})</h3>}
          <h1>평상 예약 신청이 완료되었습니다.</h1>
          <p>
            예약 신청 후 다음 계좌로 결제 금액을 이체해 주세요.
            예약이 확정되면 메시지를 보내 드립니다.
          </p>
          <div className="booking-payment">
            <p>하나은행 5809-1000-9428-04</p>
            <p>이진복(엘제이비)</p>
            <p><span>{state?.paymentAmount?.toLocaleString()}</span>원</p>
            <p>*예약신청 후 1시간 이내 이체 완료되지 않으면 예약 신청이 취소됩니다.</p>
          </div>
        </section>
      }

      <section className="lucky-spin-area">
      {(!isAfterBooking || isAvailable) &&
        <>
          <h3>럭키스핀 돌리고</h3>
          <p>수영장 할인권과 포인트를 받아보세요!</p>

          <div className="wheel-container">
            <img src={wheel.img} alt="lucky-spin-wheel" height={317} className={wheelStyle}/>
            <SpinPointer id="spin-pointer"/>
            {1 === (ticket.info.ticket1 + ticket.info.ticket2) && 
            <SpinButton1 id="spin-button" width={105} height={105} onClick={onSpin}/>}
            {2 === (ticket.info.ticket1 + ticket.info.ticket2) && 
            <SpinButton2 id="spin-button" width={105} height={105} onClick={onSpin}/>}
            {3 === (ticket.info.ticket1 + ticket.info.ticket2) && 
            <SpinButton3 id="spin-button" width={105} height={105} onClick={onSpin}/>}
            {4 === (ticket.info.ticket1 + ticket.info.ticket2) && 
            <SpinButton4 id="spin-button" width={105} height={105} onClick={onSpin}/>}
            {5 === (ticket.info.ticket1 + ticket.info.ticket2) && 
            <SpinButton5 id="spin-button" width={105} height={105} onClick={onSpin}/>}
          </div>
        </>
      }

        {(isAfterBooking && isAvailable) &&
          <Button style={{margin: "20px 0", width: 250}}
                  disabled={isShot}
                  onClick={() => {
                    onSpin();
                  }}>
            무료1회 럭키스핀 돌리기
          </Button>
        }
        {!isAfterBooking &&
          <div className="view-ad-btn">
            <Button
              onClick={() => {
                if (left.infos.send_invitation_msg < MAMIMUM_INVITATION_LIMIT) {
                  setToastMessage("친구 소환 이후 광고 시청이 가능해요");
                  return;
                }
                startAds("/pool/ad/luckyspin");
              }}
              disabled={left.infos.spin_ad > 0}
            >
              <img src={VideoIcon} alt="video-icon" height={25}/>
              광고시청
            </Button>
            <Button onClick={() => {
              if (left.infos.send_invitation_msg === MAMIMUM_INVITATION_LIMIT) {
                setToastMessage("오늘 친구 소환 완료! 내일 다시 소환해 주세요!");
                return;
              }
              summonFriends(2, "", accessToken);
            }}>
              친구초대({left.infos.send_invitation_msg}/{MAMIMUM_INVITATION_LIMIT})
            </Button>
          </div>
        }

        {(isAfterBooking && isAvailable && RTE !== "APP") &&
          <div className="app-links">
            <a href="https://apps.apple.com/kr/app/%EC%95%BC%EC%98%B9%EC%83%81%ED%9A%8C-%EC%89%BD%EA%B3%A0-%EC%A6%90%EA%B1%B0%EC%9A%B4-%ED%8F%AC%EC%9D%B8%ED%8A%B8-%EB%A6%AC%EC%9B%8C%EB%93%9C%EC%95%B1-%EC%95%B1%ED%85%8C%ED%81%AC/id6502283766" target="_blank">
              <AppleStoreLogo/>
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.yaong.app" target="_blank">
              <GooglePlayLogo/>
            </a>
          </div>
        }
      </section>

      {(!isAfterBooking || isAvailable) &&
      <div className="pool-booking-terms" style={{paddingBottom: 0}}>
        <AccordionBox header="이용 안내" defaultOpen={true}>
          <ul>
            <li>당첨된 할인 쿠폰 및 보상은 [야옹상회] 앱 서비스에서만 확인 및 사용 가능해요.</li>
            <li>[야옹상회] 앱 서비스에서는 친구 소환을 하시면 무료로 1회 스핀을 돌릴 수 있어요.</li>
          </ul>
        </AccordionBox>
      </div>
      }

      {(!isAfterBooking && RTE !== "APP") &&
        <div className="app-links" style={{paddingBottom: 40}}>
          <a href="https://apps.apple.com/kr/app/%EC%95%BC%EC%98%B9%EC%83%81%ED%9A%8C-%EC%89%BD%EA%B3%A0-%EC%A6%90%EA%B1%B0%EC%9A%B4-%ED%8F%AC%EC%9D%B8%ED%8A%B8-%EB%A6%AC%EC%9B%8C%EB%93%9C%EC%95%B1-%EC%95%B1%ED%85%8C%ED%81%AC/id6502283766" target="_blank">
            <AppleStoreLogo/>
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.yaong.app" target="_blank">
            <GooglePlayLogo/>
          </a>
        </div>
      }
      {isAfterBooking &&
        <div style={{background: 'white', padding: "24px 24px 40px"}}>
          {cancellation && <Button onClick={() => setConfirmCancellation(true)}>취소하기</Button>}
          {!cancellation && <Button onClick={() => navigate("/pool")}>홈으로 돌아가기</Button>}
        </div>
      }
      {cancellation && 
        <AlertDialog 
          open={confirmCancellation}
          title={"취소 확인"}
          text={`${state.userName}(${state.reservedDate})님의 예약을 취소 하시겠어요?`}
          buttonText={"예"}
          buttonSecondText={"아니오"}
          onOk={requestCancelReservation}
          onCancel={() => setConfirmCancellation(false)}>
        </AlertDialog>
      }
      {!cancellation && 
        <ModalDialog open={showPrize}
                    title={rewardTitle} className="alert-modal"
                    onCancel={grantPrize}>
          <p className="alert-msg">{}</p>
          <div className="spin-modal-content">
            <div>
              <img src={rewardImage} alt="spin-img" width={80}/>
            </div>
            <span>{rewardName}</span>
          </div>
          <Button onClick={grantPrize}>확인</Button>
        </ModalDialog>
      }

      <ToastAlert show={!!toastMessage} onClose={setToastMessage} text={toastMessage} />
    </React.Fragment>
  )
}