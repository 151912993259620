import {ReactComponent as CloseIcon} from "../../assets/icons/close.svg";
import {ReactComponent as ArrowIcon} from "../../assets/icons/arrow_left.svg";
import React, {useEffect, useState} from "react";

export default function SelectMenu(props) {

  const {title = "", open, setOpen, items, onSelect = () => {}} = props;

  return (
    <div className={open ? "popup-show" : "popup-hide"}>
      <div className="popup-container">
        <div className="popup-header">
          <h3>{title}</h3>
          <CloseIcon onClick={() => setOpen(false)}/>
        </div>

        <div className="select-options">
          <ul>
            {items && items.map((el, idx) => (
              <li key={"option-"+idx} onClick={() => onSelect(el)}>{el}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export function SelectField(props) {

  const {title, items, value, onSelect = (el) => {}, error = false} = props;
  const [open, setOpen] = useState(false);
  const [textColor, setTextColor] = useState("#222");

  useEffect(() => {
    setTextColor(value ?  "#222" : "#717171");
  }, [value])

  return(
    <React.Fragment>
      <div className={`select-box ${error ? " select-error" : ""}`} onClick={() => setOpen(true)}>
        <span style={{color: textColor}}>{value ?? title}</span>
        <ArrowIcon />
      </div>
      <SelectMenu
        title={title}
        open={open}
        setOpen={setOpen}
        items={items}
        onSelect={(el) => {
          onSelect(el);
          setOpen(false)
        }}
      />
    </React.Fragment>
  )
}