export default function Toggle(props) {

  const {className = "", style, ...otherProps} = props;

  return (
    <label className={`toggle-btn ${className}`} style={style}>
      <input type="checkbox" {...otherProps}/>
      <span className="slider round"/>
    </label>
  )
}