import {useLocation, useNavigate} from "react-router";
import Checkbox from "../../components/common/Checkbox";
import Button from "../../components/common/Button";
import {ReactComponent as ArrowIcon} from "../../assets/icons/arrow_left.svg";
import {ReactComponent as ArrowRight} from "../../assets/icons/vuesax/outline/arrow-right-1.svg";
import {ReactComponent as DiscountIcon} from "../../assets/icons/receipt-disscount.svg";
import CouponPic from "../../assets/images/pool-slide-1.png";
import ToryPic from "../../assets/images/image_placeholder.png";
import ModalDialog, {AlertDialog} from "../../components/common/Modal";
import {useState, useContext, useEffect} from "react";
import { AuthContext } from "../../context/AuthContext";
import useSWR from "swr";
import { format, getTime, getDay, isPast } from "date-fns";

/* const couponName = {
  1: "평상 1개 무료, 월~목/주간",
  2: "평상 1개 50%, 월~목/주간",
  3: "평상 1개 50%, 금/주간",
  11: "포인트 100",
  12: "포인트 200",
  13: "포인트 800",
  14: "포인트 1500",
  15: "포인트 2000",
  16: "포인트 2500",
  17: "포인트 3000",
  18: "포인트 4000",
  101: "무료돌리기 1회",
}; */

// 사용 가능한 쿠폰 선택 화면
export default function CouponPage(props) {

  const isMyPage = props?.myPage ?? false;

  const navigate = useNavigate();
  const { state } = useLocation();
  const location = useLocation();
  const { API_SERVER_DOMAIN, useAccessToken, fetchWithToken } = useContext(AuthContext);
  const accessToken = useAccessToken();

  const [couponInput, setCouponInput] = useState("");
  const [selectedCoupon, setselectedCoupon] = useState();
  const [selectedItem, setSelectedItem] = useState();
  const [applyCouponOpen, setApplyCouponOpen] = useState(false);
  const [regCouponOpen, setRegCouponOpen] = useState(false);
  const [couponErrorOpen, setCouponErrorOpen] = useState(false);
  // const [couponList, setCouponList] = useState(false);
  const [couponErrMsg, setCouponErrMsg] = useState(false);
  let couponList = [];

  const { data: coupon } = useSWR(accessToken && [`${API_SERVER_DOMAIN}/user/coupon`, accessToken], fetchWithToken);
  const { data: itemInfo } = useSWR(`${API_SERVER_DOMAIN}/pool/luckyspin/items`, url => fetch(url).then(res => res.json()));


  function goBack() {
    // return to previous page
    if (state.purchased) {
      navigate("/coupons");
    } else if (isMyPage) {
      navigate(-1);
    } else {
      navigate("/pool/booking", { state : { ...state, tab: 3 }});
    }
  }

  function handleClick() {
    // 수영장 쿠폰 사용 조건 확인
    // 쿠폰 1,2: 월~목 주간
    // 쿠폰 3: 금 주간
    const selectedDay = getDay(state.selectedDate);
    // console.log(state.timeType, selectedDay, selectedItem);
    if (state.timeType !== 0 || ((selectedItem === 1 || selectedItem === 2) && (selectedDay < 1 || selectedDay > 4)) || (selectedItem === 3 && selectedDay !== 5)) {
      setCouponErrMsg("사용할 수 없는 쿠폰입니다.\r\n쿠폰 사용조건을 다시 확인해 주세요.");
      return;
    }

    // 기간 지남
    if (new Date().getTime() > getTime(coupon.expiredAt)) {
      setCouponErrMsg("쿠폰 적용 기간이 지났어요.\r\n다른 쿠폰을 적용해 주세요.");
      return;
    }

    if (couponList.length) {
      state.couponId = selectedCoupon;
      state.itemId = selectedItem;
      setApplyCouponOpen(true);
    } else {
      goBack();
    }
  }

  function onClickArrowBtn() {
    if (couponInput === "") {
      setCouponErrorOpen(true);
    } else if (isMyPage) {
      setRegCouponOpen(true);
    }
  }

  function onSelectCoupon(coupon, checked) {
    if (!checked) {
      setselectedCoupon();
      setSelectedItem();
    } else {
      setselectedCoupon(coupon.couponId);
      setSelectedItem(coupon.itemId);
    }
  }

  /* useEffect(() => {
    if (isMyPage) {
      if (coupon.infos) setCouponList(coupon.infos.filter(c => c.status === "AVAILABLE"));
    } else {
      setCouponList(coupon.infos.filter(c => (c.status === "AVAILABLE" && c.type === "수영장")));
    }
  }, [coupon?.result, coupon?.infos?.length]); */

  if (!coupon || coupon.result !== "ok" || !itemInfo) return;

  if (isMyPage) {
    if (coupon.infos) couponList = coupon.infos.filter(c => c.status === "AVAILABLE");
  } else {
    couponList = coupon.infos.filter(c => (c.status === "AVAILABLE" && c.type === "수영장"));
  }

  return (
    <section className="coupon-page">
      <div>
        <ArrowIcon onClick={goBack}/>
        <h3>{isMyPage ? `${state.nickname}님의 쿠폰` : "사용 가능 쿠폰 선택"}</h3>
      </div>
      <div className="coupon-search">
        <input type="text" placeholder="쿠폰 번호를 입력하세요."
               value={couponInput} onChange={(e) => setCouponInput(e.target.value)}/>
        <button onClick={onClickArrowBtn}>
          <ArrowRight/>
        </button>
      </div>

      {couponList.length ?
        <article className="coupon-list">
          <div>
            <div>
              <DiscountIcon />
            </div>
            <p>사용 가능한 쿠폰 <span>{couponList.length ?? "-"}개</span></p>
          </div>
          <ul>
            {
              couponList.map(c => (
                <CouponItem 
                  key={c.couponId} 
                  data={c}
                  isMyPage={isMyPage}
                  isPoolPage={location.pathname === '/pool/coupon'}
                  selectedCoupon={selectedCoupon}
                  onSelect={onSelectCoupon}
                />
              ))
            }
          </ul>
        </article>
        :
        <article className="no-coupon">
          <img src={ToryPic} width={110} alt="no-available-coupon"/>
          <p>텅텅 비었어요</p>
        </article>
      }

      {!isMyPage &&
        <div className="apply-btn">
          <Button disabled={couponList?.length && !selectedCoupon}
                  onClick={handleClick}>{!couponList?.length ? "닫기" : "적용하기"}</Button>
        </div>
      }

      <ModalDialog open={applyCouponOpen}
                   title={"쿠폰 적용 완료"} className="alert-modal"
                   onClose={() => setApplyCouponOpen(false)}>
        <p className="alert-msg">{selectedItem && itemInfo.infos.find(el => el.itemId === selectedItem).itemName} 쿠폰 할인 적용이 완료되었습니다.</p>
        <Button
          onClick={() => {
            setApplyCouponOpen(false);
            goBack();
        }}>
          확인
        </Button>
      </ModalDialog>

      <AlertDialog
        open={regCouponOpen}
        onClose={() => setRegCouponOpen(false)}
        onOk={() => setRegCouponOpen(false)}
        title={"쿠폰 등록 완료"}
        text={"쿠폰 등록이 정상적으로 완료되었습니다."}
        buttonText={"확인"}
      />
      <AlertDialog
        open={couponErrorOpen}
        onClose={() => setCouponErrorOpen(false)}
        onOk={() => setCouponErrorOpen(false)}
        title={"쿠폰 오류"}
        text={"정상적인 쿠폰번호가 아니에요. 정상적인 쿠폰이 맞는지 다시 확인해주세요"}
        buttonText={"확인"}
      />
      <AlertDialog
        open={!!couponErrMsg}
        onClose={() => setCouponErrMsg(false)}
        onOk={() => setCouponErrMsg(false)}
        title={"쿠폰 오류"}
        text={couponErrMsg}
        buttonText={"확인"}
      />
    </section>
  )
}

function CouponItem(props) {

  const { data, onSelect, isMyPage, isPoolPage, selectedCoupon } = props;
  const navigate = useNavigate();
  const isExipred = isPast(data.expiredAt);

  const couponImage = {
    "수영장": CouponPic,
    "야옹콘": data.goodsImgUrl,
  };

  function onClick() {
    if (isExipred) return;

    let link = isMyPage ? "/user/coupons/detail" : "/coupons/detail";
    
    if (!isPoolPage) navigate(link, {state: {...data}})
  }

  return (
    <li className="coupon-item" onClick={onClick}>
      <img src={couponImage[data.type]} alt="coupon"/>
      <div className={isExipred ? "expired" : ""}>
        <p>[{data.type === "수영장" ? `히든밸리 ${data.type}` : data.type} | 쿠폰번호 <span>{data.couponSerial}</span>]</p>
        <p>{data.itemName ?? data.goodsName}</p>
        <p className="coupon-date">{format(data.createdAt, "yyyy. MM. dd")} ~ {format(data.expiredAt, "yyyy. MM. dd")}</p>
      </div>
      {!isMyPage && <Checkbox checked={selectedCoupon === data.couponId} onClick={(checked) => onSelect(data, checked)}/>}
    </li>
  )
}