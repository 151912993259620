import {createContext, useState} from 'react';

export const FrontLayerContext = createContext({});

// 전체화면을 덮어서 보이는 컴포넌트에서 사용
function FrontLayerProvider({children}) {

  // 모달 펍업창 열기
  const [openModal, setOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState(<> </>)

  // 날짜 선택 영역 열기
  const [openCalendar, setOpenCalendar] = useState(false);
  const [onSelectDate, setOnSelectDate] = useState();


  return <FrontLayerContext.Provider value={{
    openModal, setOpenModal,
    modalContent, setModalContent,
    onSelectDate, setOnSelectDate,
    openCalendar, setOpenCalendar
  }}>
    {children}
  </FrontLayerContext.Provider>
}

export {FrontLayerProvider};