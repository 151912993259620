import {ReactComponent as ArrowIcon} from "../../assets/icons/arrow_left.svg";
import NewMark from "../../assets/icons/new.png";
import React, {useState, useContext} from "react";
import {useNavigate} from "react-router";
import {AuthContext} from "../../context/AuthContext";
import useSWR from "swr";
import {differenceInHours, differenceInDays} from "date-fns";
import ToryPic from "../../assets/images/image_placeholder.png";

export default function NotificationsPage() {

  const navigate = useNavigate();

  const filters = ["all", "notice", "service", "etc"];
  const filterLabel = {"all": "전체", "notice": "공지", "service": "서비스", "etc": "기타"};
  const [selectedFilter, setSelectedFilter] = useState("all");
  const { API_SERVER_DOMAIN, useAccessToken, fetchWithToken } = useContext(AuthContext);
  const accessToken = useAccessToken();

  const { data: notice, mutate } = useSWR(accessToken && [`${API_SERVER_DOMAIN}/board/notice?type=all&page=1&countPerPage=30`, accessToken], fetchWithToken);

  // 공지사항 읽음처리
  const readNotice = async id => {
    const response = await fetch(`${API_SERVER_DOMAIN}/board/notice`, {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        "noticeId": id
      })
    });
    const jsonData = await response.json();

    if (jsonData.result === "ok") {
      mutate();
    } else {
      console.error(jsonData.message);
    }
  }

  if (!notice || notice.result !== "ok") return <></>;

  return (
    <section>
      <div className="page-header header-shadow">
        <ArrowIcon onClick={() => navigate(-1)} style={{left: 24}}/>
        <h3>알림 <span className="text-extra-light" style={{fontSize: 14, fontWeight: 400}}>{notice.infos.length}</span></h3>
      </div>

      <div className="notification-filter">
        {filters.map((item) => (
          <div key={item} className={selectedFilter === item ? "selected" : ""}
               onClick={() => setSelectedFilter(item)}>
            {filterLabel[item]}
          </div>
        ))}
      </div>

      <article className="notification-list">
        <ul>
          {notice.infos.filter(el => (selectedFilter === "all") ? true : el.type === selectedFilter).map((data) => (
            <li key={"notification-"+data.noticeId} className={data.read ? "read" : "unread"} onClick={() => readNotice(data.noticeId)}>
              <span className="text-light">[{filterLabel[data.type]}]</span>
              <p className="notification-content">{data.content}</p>
              <div className="timestamp-and-new">
                {!data.read && <img src={NewMark} alt="new-mark" width={15}/>}
                <p className="text-extra-light timestamp">
                  {
                    (differenceInHours(new Date(), data.createdAt) < 24 && `${differenceInHours(new Date(), data.createdAt)}시간 전`) ||
                    (`${differenceInDays(new Date(), data.createdAt)}일 전`)
                  }
                </p>
              </div>
            </li>
          ))}
        </ul>
        {notice.infos.filter(el => (selectedFilter === "all") ? true : el.type === selectedFilter).length === 0 &&
          <article className="no-content">
            <img src={ToryPic} width={110} alt="no-available-content"/>
            <p>내용이 없어요.</p>
          </article>
        }
      </article>
    </section>
  )
}