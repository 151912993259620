import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";

export default function OAuth() {
  const { signIn } = useContext(AuthContext);
  const urlParams = new URLSearchParams(window.location.search);
  const accessToken = urlParams.get("accessToken");
  const accessTokenExpiresIn = urlParams.get("accessTokenExpiresIn");
  const refreshToken = urlParams.get("refreshToken");
  
  signIn(accessToken, accessTokenExpiresIn, refreshToken);
}