import {useLocation, useNavigate} from "react-router";
import React, {useState} from "react";
import {ReactComponent as ArrowIcon} from "../../assets/icons/arrow_left.svg";

export default function RewardDetailPage() {

  const navigate = useNavigate();
  const location  = useLocation();

  const description = location.state?.description ?? "test";
  const imgUrl = location.state?.imgUrl ?? "test";

  const [selectedTab, setSelectedTab] = useState(1)

  return (
    <section>
      <div className="page-header header-shadow">
        <ArrowIcon onClick={() => navigate("/tory-game")} style={{left: 24}}/>
        <h3>우승 리워드 쿠폰</h3>
      </div>

      <div className="reward-img">
        <div className="card-img reward-img" style={{background: `url(${imgUrl}) no-repeat`}}/>
        <p>{description}</p>
        <span className="text-light">시작까지 남은 시간</span>
        <span className="remained-time">{location.state.remainedTime}</span>
      </div>

      <div className="basic-tabs">
        <div onClick={() => setSelectedTab(1)} className={selectedTab === 1 ? "selected-basic-tab" : ""}>
          <span>플레이 방법</span>
        </div>
        <div onClick={() => setSelectedTab(2)} className={selectedTab === 2 ? "selected-basic-tab" : ""}>
          <span>아이템 사용</span>
        </div>
        <div onClick={() => setSelectedTab(3)} className={selectedTab === 3 ? "selected-basic-tab" : ""}>
          <span>주의사항</span>
        </div>
      </div>
      <div className="reward-detail">
        {selectedTab === 1 && <PlayGuide/>}
        {selectedTab === 2 && <ItemUse/>}
        {selectedTab === 3 && <Precautions/>}
      </div>

    </section>
  )
}

function PlayGuide() {
  return (
    <>
      <h4>[토리를 구해줘]</h4>
      <ol>
        <li>START 1분 전 입장 버튼이 활성화되니, 입장 전에 입장비 포인트와 에너지를 꼭 확인하세요. (플레이 타임: 3분)</li>
        <li>각자의 방에서 플레이가 진행되며 플레이 타임은 3분이에요. (추후 온라인 버전으로 개발 예정)</li>
        <li>게임 시작 후, 맵에 떨어져 있는 토리를 상대보다 먼저 보유하세요.</li>
        <li>플레이 중 자유롭게 아이템 사용이 가능하며, 아이템은 아이템상점에서 구매 가능합니다.</li>
        <li>가장 오랜 시간 토리를 소유한 사람이 우승자가 됩니다.</li>
      </ol>
    </>
  )
}

function ItemUse() {
  return (
    <>
      <h4>아이템 사용</h4>
      <ol>
        <li>토리를 뺏으면 4초간 적으로부터 보호 상태가 발동돼요. (보호막 아이템 사 용 시 시간만큼 연장)</li>
        <li>뺏기 아이템은 가장 먼저 사용한 러너의 아이템만 적용돼요.</li>
        <li>보호막 사용 중 : 뺏기 아이템 사용 시, 뺏기 아이템 효과 없음 → 뺏기 아이 템 소진</li>
        <li>보호막 사용 중 부스터 사용 가능해요.</li>
        <li>보호막 사용 중 뺏기 사용 가능가능해요.</li>
        <li>최대 속력은 60Km/h로 제한하고 있어요. - 최저 속력 : 30Km/h, - 부스터 사용 : 2배</li>
        <li>광고 시청 후 아이템 획득 : 24시에 리셋</li>
      </ol>
    </>
  )
}

function Precautions() {
  return (
    <>
      <h4>주의사항</h4>
      <ol>
        <li>방이 열린 후 진입 하신 유저는 플레이가 종료 될 때까지 나갈 수 없어요.</li>
        <li>만약 방을 고의로 강제 이탈 할 경우 포인트 입장비를 돌려 받을 수 없어요. </li>
        <li>획득 하신 쿠폰은 야옹콘에서 사용할 기프티콘일 경우 30일 내 사용 하셔야 해요.</li>
        <li>우승자 조건</li>
        <ol type={"a"}>
          <li>가장 오랫동안 토리를 보유한 유저</li>
          <li>아이템을 가장 많이 사용한 유저</li>
          <li>가장 먼저 입장한 유저</li>
        </ol>
      </ol>
    </>
  )
}

