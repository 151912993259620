import { DayPicker } from 'react-day-picker';
import { differenceInCalendarDays, isSameDay, isAfter } from 'date-fns';
import 'react-day-picker/dist/style.css';
import { ko } from 'date-fns/locale';
import "../../assets/styles/components.scss"
import {useContext, useState} from "react";
import {FrontLayerContext} from "../../context/FrontLayerContext";
import {ReactComponent as CloseIcon} from "../../assets/icons/close.svg";
import {format} from "date-fns";
// 테스트 기간 임시 추가
import {AuthContext} from "../../context/AuthContext";
import useSWRImmutable from "swr/immutable";
import useSWR from "swr";

export default function DatePicker() {

  const {
    openCalendar, setOpenCalendar, onSelectDate
  } = useContext(FrontLayerContext);


  const [selected, setSelected] = useState();

  // 테스트 기간 임시 추가
  const { API_SERVER_DOMAIN, useAccessToken, fetchWithToken } = useContext(AuthContext);
  const accessToken = useAccessToken();
  const { data: user } = useSWRImmutable(accessToken && [`${API_SERVER_DOMAIN}/auth/user/check`, accessToken], fetchWithToken);
  const { data: pool } = useSWR(`${API_SERVER_DOMAIN}/pool/info`, url => fetch(url).then(res => res.json()));
  if (!user || !pool) return;

  const formatCaption = (date, options) => {
    return (
      <>
        {format(date, 'yyyy년 MM월', { locale: options?.locale })}
      </>
    );
  };

  const notWithinTwoWeeks = date => {
    if ([4, 7].includes(user.userInfo.userNo)) {
      return differenceInCalendarDays(date, new Date()) < 0 || isSameDay(date, pool.infos.closedDate) || isAfter(date, pool.infos.price.endDt);
    } else {
      return (differenceInCalendarDays(date, new Date()) < 0 || differenceInCalendarDays(date, new Date()) > 14) || isSameDay(date, pool.infos.closedDate) || isAfter(date, pool.infos.price[pool.infos.price.length - 1].endDt);
    }
  }

  return (
    <div className={openCalendar ? "popup-show" : "popup-hide"}>
      <div className="popup-container">
        <div className="popup-header">
          <h3>예약날짜 선택</h3>
          <CloseIcon onClick={() => setOpenCalendar(false)}/>
        </div>

        <div className="date-picker-calendar">
          <DayPicker
            mode="single"
            selected={selected}
            onSelect={(val) => {
              setSelected(val);
              if (onSelectDate) onSelectDate(val);
              setOpenCalendar(false);
            }}
            locale={ko}
            formatters={{formatCaption}}
            disabled={notWithinTwoWeeks}
          />
        </div>
      </div>
    </div>
  )
}