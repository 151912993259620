import {ReactComponent as CloseIcon} from "../../assets/icons/close.svg";
import React, {useState} from "react";
import {useNavigate} from "react-router";
import {TextField} from "../../components/common/Input";
import Button from "../../components/common/Button";
import ModalDialog from "../../components/common/Modal";
import ToastAlert from "../../components/common/Toast";
import {useEffect} from "react";

export default function ToryGameEnd() {

  const navigate = useNavigate();

  const [emptyInputAlert, setEmptyInputAlert] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [textCount, setTextCount] = useState("0");

  const [errors, setErrors] = useState([]);
  const [address, setAddress] = useState("");
  const [name, setName] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [nickname, setNickname] = useState("");
  const [email, setEmail] = useState("");


  function validateForm() {
    if (address.length > 0 && name.length > 0 && nickname.length > 0 &&
      phoneNum.length > 0 && email.length > 0) {
      setConfirmOpen(true);
    } else {
      if (address.length === 0) setErrors(prev => [...prev, "address"]);
      if (name.length === 0) setErrors(prev => [...prev, "name"]);
      if (phoneNum.length === 0) setErrors(prev => [...prev, "phoneNum"]);
      if (email.length === 0) setErrors(prev => [...prev, "email"]);
      if (!nickname) setErrors(prev => [...prev, "nickname"]);
      setEmptyInputAlert(true);
    }
  }

  useEffect(() => {
    document.getElementsByClassName("page-container")[0]?.scrollTo(0, 0);
  }, [])

  return (
    <section className="address-input-page">
      <div className="page-header header-shadow">
        <h1>배송지 입력</h1>
        <CloseIcon onClick={() => navigate("/playground")}/>
      </div>

      <article className="ticket-cancel-page">
        <h2>주소 입력</h2>
        <div className={`text-area-input${errors.includes("address") ? " text-area-error" : ""}`} style={{}}>
          <textarea name="address" id="address-input" rows="6"
                    placeholder={"배송 받으실 분의 주소를 정확히 입력해 주세요\n주소가 정확하지 않을 경우 배송이 안될 수도 있어요."}
                    onChange={(e) => {
                      setAddress(e.target.value);
                      setTextCount(e.target.value.length.toLocaleString());
                      setErrors(prev => prev.filter(el=>el!=="address"));
                    }}
          />
          <span className="text-count">{textCount}/5,000</span>
        </div>

        <h2>기타 정보 모두 입력해주세요</h2>
        <div className="ticket-cancel-form">
          <TextField id={"winner-name"} label={"성명"}
                     value={name} error={errors.includes("name")}
                     onChange={(value) => {
                       setName(value);
                       setErrors(prev => prev.filter(el=>el!=="name"));
                     }}
                     placeholder={"배송 받으실 분의 성명을 입력해 주세요"}/>
          <TextField id={"winner-nickname"} label={"닉네임"}
                     error={errors.includes("nickname")}
                     onChange={(value) => {
                       setNickname(value);
                       setErrors(prev => prev.filter(el=>el!=="nickname"));
                     }}
                     helperText={"반드시 게임 참여 하신 분의 성함을 입력해 주세요"}
                     placeholder={"야옹상회 닉네임을 입력해 주세요"}/>
          <TextField id={"winner-contact"} label={"연락처"}
                     error={errors.includes("phoneNum")}
                     onChange={(value) => {
                       setPhoneNum(value);
                       setErrors(prev => prev.filter(el=>el!=="phoneNum"));
                     }}
                     helperText={"반드시 예약자 본인의 연락처를 입력해야 합니다."}
                     placeholder={"000-0000-0000"}/>
          <TextField id={"winner-email"} label={"이메일"}
                     value={email} error={errors.includes("email")}
                     onChange={(value) => {
                       setEmail(value);
                       setErrors(prev => prev.filter(el=>el!=="email"));
                     }}
                     placeholder={"이메일을 입력해 주세요."}/>
        </div>
      </article>

      <div className="page-action-btn ticket-cancel-btn">
        <Button className="prev-btn" onClick={() => navigate("/playground")}>취소하기</Button>
        <Button onClick={validateForm}>보내기</Button>
      </div>

      <ToastAlert show={emptyInputAlert} onClose={setEmptyInputAlert} text={"정보를 입력해 주세요!"} />

      <ModalDialog open={confirmOpen}
                   title={"배송지 정보 확인"} className="alert-modal"
                   onCancel={() => setConfirmOpen(false)}>
        <div className="address-info-check">
          <span>주소 :</span>
          <span>{address}</span>
          <span>성명 :</span>
          <span>{name}</span>
          <span>닉네임 :</span>
          <span>{nickname}</span>
          <span>연락처 :</span>
          <span>{phoneNum}</span>
          <span>이메일 :</span>
          <span>{email}</span>
        </div>
        <p className="alert-msg">배송지 정보가 맞나요? 보내기 하시겠습니까?</p>
        <div className="alert-action-btn">
          <Button className="prev-btn" onClick={() => setConfirmOpen(false)}>돌아가기</Button>
          <Button  onClick={() => {
            setConfirmOpen(false);
            navigate("/playground");
          }}>보내기</Button>
        </div>
      </ModalDialog>
    </section>
  )
}