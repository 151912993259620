import {ReactComponent as ArrowIcon} from "../../assets/icons/arrow_left.svg";
import React, {useState, useEffect, useContext} from "react";
import {useLocation, useNavigate} from "react-router";
import Button from "../../components/common/Button";
import RandomLuckCat from "../../assets/images/random_luck_cat.png";
import ImgEffect from "../../assets/images/img_effect.png";
import PointImg from "../../assets/images/point-large.png";
import RewardVideo from "../../assets/images/Confetti_transparent.webm";
import ToastAlert from "../../components/common/Toast";
import {getPaidToWatchAds, useAdsListener} from "../../services/admob";
import {useRunTimeEnvironment} from "../../utils/hooks";
import {AuthContext} from "../../context/AuthContext";


export default function RandomLuckPage() {

  const navigate = useNavigate();
  const location  = useLocation();
  const RTE = useRunTimeEnvironment();

  const [successOpen, setSuccessOpen] = useState(false);
  const [rewardPoints, setRewardPoints] = useState(0);
  const [alertMessage, setAlertMessage] = useState(false);

  const {API_SERVER_DOMAIN, useAccessToken, fetchWithToken} = useContext(AuthContext);
  const accessToken = useAccessToken();

  let adKey = '';

  async function getRandomLuck() {
    const response = await fetch(`${API_SERVER_DOMAIN}/user/attendance/ad/random`, {
      method: "POST",
        headers: {
          "Authorization": `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({code: adKey})
    });
    const jsonData = await response.json();
    setRewardPoints(jsonData.recvPoint);

    setSuccessOpen(true);
  }

  useAdsListener("", "", {}, getRandomLuck);

  useEffect(() => {
    let pageContainer = document.getElementsByClassName("page-container")[0];
    pageContainer?.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section>
      <div className="page-header header-shadow">
        <ArrowIcon onClick={() => navigate("/attendance-check")} style={{left: 24}}/>
        <h3>깜짝 야옹이 행운</h3>
      </div>

      {!successOpen &&
        <article className="random-luck-page">
          <div>
            <p>랜덤포인트 행운을 주는 야옹이가 등장했다 야옹!</p>
          </div>

          <div className="random-luck-cat">
            <img src={ImgEffect} alt="bg-effect" width={280}/>
            <img src={RandomLuckCat} alt="bg-effect" width={130}/>
          </div>

          <h1><b className="text-accent">랜덤행운</b> 야옹이 등장!!</h1>
          <p>광고를 보시면 야옹이가 한번 더 랜덤 포인트를 드린다야옹~</p>

          <Button onClick={async () => {
            const response = await getPaidToWatchAds(RTE, `${API_SERVER_DOMAIN}/user/attendance/ad/random`, accessToken);
            if (response.adKey) {
              adKey = response.adKey;
            } else {
              setAlertMessage(response.message);
            }
          }}>
            광고 보고 랜덤 포인트 받기
          </Button>

          <div>
            <p className="text-grey">광고를 끝까지 시청하고, 광고 종료 버튼 클릭까지 완료하셔야 포인트가 지급 돼요.</p>
          </div>
        </article>
      }

      {successOpen &&
        <article className="random-luck-done">
          <div>
            <img src={PointImg} alt="points" height={100}/>
            <h3>{rewardPoints}포인트를 받았어요!</h3>
            <p>행운의 주인공은 당신이다야옹~</p>

            <div id="bg-reward">
              <video autoPlay muted loop>
                <source src={RewardVideo} type="video/mp4"/>
              </video>
            </div>
          </div>
          <Button onClick={() => navigate("/attendance-check")}>확인</Button>
        </article>
      }

      <ToastAlert show={!!alertMessage} onClose={setAlertMessage} text={alertMessage} timeout={2500} />
    </section>
  )
}