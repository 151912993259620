import "../../assets/styles/ticket.scss";
import {ReactComponent as ArrowIcon} from "../../assets/icons/arrow_left.svg";
import ToryPic from "../../assets/images/image_placeholder.png";
import {useNavigate} from "react-router";
import React, {useState} from "react";
import Button from "../../components/common/Button";
import SelectMenu from "../../components/common/Select";
import ModalDialog from "../../components/common/Modal";
import {useEffect, useContext} from "react";
import useSWR from "swr";
import { AuthContext } from "../../context/AuthContext";
import { format, getDay, isWithinInterval } from "date-fns";


export default function TicketPage() {

  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(1);
  const [openOptions, setOpenOptions] = useState(false);
  const [ticketIndex, setTicketIndex] = useState(0);

  const [changeSeatOpen, setChangeSeatOpen] = useState(false);
  const { API_SERVER_DOMAIN, useAccessToken, fetchWithToken } = useContext(AuthContext);
  const accessToken = useAccessToken();

  const { data: activeData } = useSWR(accessToken && [`${API_SERVER_DOMAIN}/pool/book?isActive=true`, accessToken], fetchWithToken);
  const { data: inactiveData } = useSWR(accessToken && [`${API_SERVER_DOMAIN}/pool/book?isActive=false`, accessToken], fetchWithToken);
  const { data: poolInfo } = useSWR(`${API_SERVER_DOMAIN}/pool/info`, url => fetch(url).then(res => res.json()));

  function onClickOptions(index) {
    setTicketIndex(index);
    setOpenOptions(true);
  }

  function onSelectOption(item) {
    setOpenOptions(false);
    switch (item) {
      case "평상 위치 변경하기": return setChangeSeatOpen(true);
      case "예약 취소하기": 
        const bookNo = activeData.infos[ticketIndex].bookNo;
        if (activeData.infos[ticketIndex].status === "RESERVED") {
          return navigate("/pool/ticket/cancel", { state: { bookNo: bookNo }});
        } else {
          const state = {
            mode: "delete",
            bookNo: bookNo,
            paymentAmount: activeData.infos[ticketIndex].bookingBill,
            userName: activeData.infos[ticketIndex].name,
            reservedDate: activeData.infos[ticketIndex].reservedDate
          };
          return navigate("/pool/booking/success", { state: state });
        }
      default: return null;
    }
  }

  useEffect(() => {
    document.getElementsByClassName("page-container")[0]?.scrollTo(0, 0);
  }, [])

  return (
    <section className="ticket-page">
      <div className="page-header">
        <ArrowIcon onClick={() => navigate("/pool")} style={{left: 24}}/>
        <h3>티켓예약 현황</h3>
      </div>
      <div className="basic-tabs">
        <div onClick={() => setSelectedTab(1)} className={selectedTab===1 ? "selected-basic-tab" : ""}>
          <span>예약 현황</span>
        </div>
        <div onClick={() => setSelectedTab(2)} className={selectedTab===2 ? "selected-basic-tab" : ""}>
          <span>예약 내역</span>
        </div>
      </div>
      {selectedTab === 1 ?
        <article>
          {
            activeData?.infos?.map((data, index) => (
              <Ticket key={data.bookId} props={data} isPast={false} index={index} onClickOptions={onClickOptions}/>
            ))
          }
        </article>
        :
        <article>
          {
            inactiveData?.infos?.map(data => (
              <Ticket key={data.bookId} isPast={true} props={data} onClickOption={f => f}/>
            ))
          }
        </article>
      }
      {((selectedTab === 1 && activeData?.infos?.length === 0) || (selectedTab === 2 && inactiveData?.infos?.length === 0)) && 
        <article className="no-content">
          <img src={ToryPic} width={110} alt="no-ticket"/>
          <p>내용이 없어요.</p>
        </article>
      }

      <SelectMenu
        title="히든밸리 예약 설정"
        open={openOptions}
        setOpen={setOpenOptions}
        items={["평상 위치 변경하기", "예약 취소하기"]}
        onSelect={onSelectOption}
      />

      <ModalDialog open={changeSeatOpen}
                   title={"평상 자리 변경"} className="alert-modal"
                   onClose={() => setChangeSeatOpen(false)}>
        <p className="alert-msg" style={{minWidth: 250}}>평상 자리를 변경하시겠어요?</p>
        <Button onClick={() => {
          setChangeSeatOpen(false);

          const selectedDate = activeData.infos[ticketIndex].reservedDate;
          const period = poolInfo.infos.price.find(item => isWithinInterval(selectedDate, {start: item.beginDt, end: item.endDt}));
          const pricing = {
            bed: period.bed,
            bungalow: period.bungalow,
            adult: period.adult,
            junior: period.junior,
          };
          const props = {
            modify: true,
            bookNo: activeData.infos[ticketIndex].bookNo,
            benchCount: activeData.infos[ticketIndex].bed,
            bedLocation: activeData.infos[ticketIndex].bedLocation,
            selectedDate: selectedDate,
            timeType: activeData.infos[ticketIndex].reservedTimeType - 1,
            pricing: pricing
          };
          navigate("/pool/booking", { state: { tab: 2, modify: true, props: props }});
        }}>
          평상 변경하러 가기
        </Button>
      </ModalDialog>
    </section>
  )
}


function Ticket({ props, isPast, index, onClickOptions }) {

  const isPaid = props.status === "RESERVED";

  const [timeType, timeAvailable] = (() => {
    switch (props.reservedTimeType) {
      case 1:
        return ["주간", "09:00~17:00"];
      case 2:
        return ["야간", "17:30~21:30"];
      case 3:
        return ["종일", "09:00~21:30"];
      default:
        return ["", ""];
    }
  })();

  const [payStatus, ticketStatus] = (() => {
    switch (props.status) {
      case "WAITING":
        return ["대기중", "예약결제 금액 입금 대기중"];
      case "RESERVED":
        return ["입금완료", isPast ? "기간만료" : "예약완료"];
      case "DONE":
        return ["입금완료", "사용완료"];
      case "CANCELLED":
        return ["미입금", "취소됨"];
      case "CANCEL_REQUEST":
        return ["입금완료", "예약 취소 신청 중"];
      default:
        return ["", ""];
    }
  })();

  const reservedDay = (() => {
    switch (getDay(props.reservedDate)) {
      case 0: return "일";
      case 1: return "월";
      case 2: return "화";
      case 3: return "수";
      case 4: return "목";
      case 5: return "금";
      case 6: return "토";
      default: return "";
    }
  })();

  const expireMessage = "해당 예약권은 예약일이 만료되어 더 이상 이용이 불가능합니다. 자세한 사항은 010-2138-3563로 문의하시기 바랍니다.";
  const cancelMessage = "해당 예약권은 예약금 입금시간이 만료되어 이용이 불가능합니다. 다시 예약하신 후, 시간 안에 예약금 입금을 완료해주세요. 자세한 사항은 010-2138-3563로 문의하시기 바랍니다.";
  const navigate = useNavigate();

  return (
    <div className="ticket-detail">
      <div>
        <h1>히든밸리 {timeType} 예약권</h1>
        {!isPast && (props.status === "WAITING" || props.status === "RESERVED") &&
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={() => onClickOptions(index)}>
            <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#222222" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z" stroke="#222222" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z" stroke="#222222" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        }
      </div>
      <div className={`rounded-tag ${!isPast ? "current-ticket-tag" : ""}`}>
        <span>입장 시간</span>
        <span>{timeAvailable}</span>
      </div>
      <div className="break-line"/>

      <dl>
        <dt>예약번호</dt>
        <dd>{props.bookId}</dd>
        <dt>예약일</dt>
        <dd>{format(props.reservedDate, "yyyy. MM. dd")}({reservedDay})</dd>
        <dt>입장 인원</dt>
        <dd>대인 {props.adult}, 소인 {props.junior}</dd>
        <dt>평상 예약</dt>
        <dd>
          {props.reservedTimeType === 2 ? "야간 평상은 현장에서 선착순으로 선택해요!" : props.bedLocation.replaceAll("\"", "")}
        </dd>
        <dt>현재 상태</dt>
        <dd className={isPaid || isPast ? "" : "dd-status"}>{ticketStatus}</dd>
      </dl>
      {
        (props.status === "RESERVED" && isPast) && 
          <div className="ticket-history-message">
            <p>{expireMessage}</p>
          </div>
      }
      {
        props.status === "CANCELLED" &&
          <div className="ticket-history-message">
            <p>{cancelMessage}</p>
          </div>
      }
      
      {props.status === "WAITING" &&
        <div className="ticket-payment-info">
          <p>하나은행 5809-1000-9428-04</p>
          <p>이진복(엘제이비)</p>
          <p><span style={{fontSize: 28, fontWeight: 900}}>{props.bookingBill.toLocaleString()}</span>원</p>
          <hr/>
          <p>예약 완료 이후 1시간 안에 위 계좌로 평상예약금 {props.bookingBill.toLocaleString()}원을 입금해주세요. (예약 신청 후 <span>1시간</span> 이내 이체 완료되지 않으면 예약 신청이 취소돼요)</p>

          <ul>
            <li>입금자명 뒤에 핸드폰 뒷 네자리를 기입해주세요. (예:김야옹1234)</li>
            <li>예약자명과 입금자명이 상이할 경우 010-2138-3563으로 문자 남겨주세요.</li>
            <li>예약 내용과 예약금을 확인 이후 확정 메시지가 발송돼요. 메시지를 받지 못한 경우 위 번호로 문의하세요.</li>
            <li>예약 내용과 예약금이 불일치할 경우 예약 확정이 불가해요. 확정이 불가할 경우에는 별도 안내에 따라 예약을 완료해주세요.</li>
          </ul>
        </div>
      }
      <div className="break-line"/>

      <div className="payment-details">
        <span className="payment-title text-accent" style={{fontSize: 18, fontWeight: 'bold'}}>평상 예약 결제</span>

        <div className="price-and-status">
          <span className="price">{props.bookingBill.toLocaleString()}<span>원</span></span>
          <span className={isPast ? "ticket-pay-history-status" : "ticket-pay-status"}>
            {payStatus}
          </span>
        </div>

        <p>예약자: {props.name}</p>
        <dl>
          <dt>평상구매(일반형, {timeType}) {props.bed}</dt>
          <dd>{props.bookingBill.toLocaleString()}원</dd>
          <dt>총 금액</dt>
          <dd>{props.bookingBill.toLocaleString()}원</dd>
        </dl>

        <div className="break-line"/>

        <p>현장에 오시면 결제 하실 입장비용입니다.</p>
        <dl>
          <dt>대인({timeType}) {props.adult}</dt>
          <dd className="text-grey">{props.onsiteBillAdult.toLocaleString()}원</dd>
          <dt>소인({timeType}) {props.junior}</dt>
          <dd className="text-grey">{props.onsiteBillJunior.toLocaleString()}원</dd>
          <dt>현장에서 결제할 예상 입장료</dt>
          <dd className="text-grey">{(props.onsiteBillAdult + props.onsiteBillJunior).toLocaleString()}원</dd>
        </dl>
      </div>

      {props.status === "CANCELLED" &&
        <>
          <div className="break-line"/>
          <Button onClick={() => navigate("/pool")}>다시 예약하기</Button>
        </>
      }
    </div>
  )
}