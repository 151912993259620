import React, {useState} from 'react';

export default function Checkbox(props) {

  const {checked, readOnly, label, description} = props;
  const id = props.id ?? "checkbox" + (Math.random() * 100).toFixed();

  const [value, setValue] = useState(false);

  function onClickCheckbox(e) {
    e.stopPropagation();
    setValue(e.target.checked);
    if (props.onClick) props.onClick(e.target.checked);
  }

  return (
    <div className="checkbox-wrapper">
      <label className="checkbox">
        <input type="checkbox" disabled={readOnly ?? false}
               id={id} name={id}
               checked={checked ?? value}
               onChange={e => onClickCheckbox(e)}/>
        <span className="custom-checkmark"> </span>
      </label>
      {label &&
        <div>
          <label htmlFor={id}>{label}</label>
          {description &&
            <p>{description}</p>
          }
        </div>
      }
    </div>
  )
}